<template>
  <modal-padrao ref="modal-input" :max-width="1000" :titulo="$t('componentes.input_instrumento.titulo_modal')"
    :titulo-ok="$t('geral.botoes.confirmar')">
    <v-stepper v-model="indiceAba" color="transparent">
      <v-stepper-header>
        <div v-for="(item, index) in quantidadeAbas" :key="index">
          <v-stepper-step :complete="indiceAba > item" :step="item" class="no-padding">
            {{ tituloAbas[index] }}
          </v-stepper-step>
        </div>
      </v-stepper-header>

      <v-divider />
    </v-stepper>

    <TipoInstrumentoModal v-if="indiceAba === 1" v-model="selecionadosTipoInstrumento" :multiplos="false" />
    <InstrumentoModal v-if="indiceAba === 2" ref="instrumento-modal" v-model="selecionadosInstrumento"
      :tipos-instrumentos="tipoInstrumento" :participante-id="participanteId" />

      <template v-slot:botoes>
          <botao-padrao outlined color="secondary" class="mr-2" @click="mudarAba('cancelar-voltar')">
            {{ textoCancelarVoltar }}
          </botao-padrao>
          <botao-padrao color="primary" :disabled="!selecionadosTipoInstrumento.length"
            @click="mudarAba('confirmar-avancar')">
            {{ textoAvancarConfirmar }}
          </botao-padrao>
    </template>
  </modal-padrao>
</template>
<script>
import TipoInstrumentoModal from '@components/inputs/especificos/InputTipoInstrumento/modal.vue';
import InstrumentoModal from '@components/inputs/especificos/InputInstrumento/modal.vue';
import { DropdownModel } from '@common/models/geral/DropdownModel.js';
export default {
  components: { TipoInstrumentoModal, InstrumentoModal },
  props: {
    participanteId: { type: String, default: '' },
  },
  data() {
    return {
      selecionadosTipoInstrumento: [],
      selecionadosInstrumento: [],
      indiceAba: 1,
      quantidadeAbas: 2,
      tituloAbas: [
        this.$t('modulos.tipo_instrumento.titulos.tipo_instrumento'),
        this.$t('modulos.instrumento.titulos.instrumento'),
      ],
    };
  },
  computed: {
    textoCancelarVoltar() {
      return this.indiceAba == 1
        ? this.$t('geral.botoes.cancelar')
        : this.$t('geral.botoes.voltar');
    },
    textoAvancarConfirmar() {
      return this.indiceAba == this.quantidadeAbas
        ? this.$t('geral.botoes.confirmar')
        : this.$t('geral.botoes.avancar');
    },
    tipoInstrumento() {
      return new DropdownModel(this.selecionadosTipoInstrumento[0]);
    },
  },
  methods: {
    abrirModal() {
      this.$refs['modal-input'].abrirModal();
    },
    confirmarInstrumentos() {
      this.$emit(
        'associarInstrumentos',
        { selected: this.selecionadosInstrumento },
        'add'
      );
      this.indiceAba = 1;
      this.selecionadosInstrumento = [];
      this.selecionadosTipoInstrumento = [];
      this.$refs['modal-input'].fecharModal();
    },
    trocarAbaParaInstrumentos() {
      ++this.indiceAba;
      this.$nextTick().then(() => {
        this.$refs['instrumento-modal'].listar();
      });
    },
    mudarAba(passo) {
      if (passo == 'confirmar-avancar') {
        this.indiceAba != this.quantidadeAbas
          ? this.trocarAbaParaInstrumentos()
          : this.confirmarInstrumentos();
        return;
      }
      this.indiceAba != 1
        ? --this.indiceAba
        : this.$refs['modal-input'].fecharModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.v-stepper::v-deep {
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;

  .v-stepper__header {
    position: relative;
    z-index: 2;

    div {
      background: #ffffff;
    }
  }

  .v-divider {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    right: 0;
  }
}
</style>

<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <div>
        <botao-padrao
          v-if="podeExcluir && !visualizacao"
          class="my-2"
          outlined
          color="danger"
          @click="excluirRegistros"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <botao-padrao
        v-if="!visualizacao"
        class="mt-3"
        @click="abreModalAnexo"
      >
        {{ $t('modulos.orcamentos.formulario.anexar_arquivo') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      sem-paginacao
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:item.acoes="{ item }">
        <dropdown-padrao
          v-if="!visualizacao"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item @click="fazerDownloadArquivo(item.item)">
            {{ $t('geral.botoes.download') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
    </tabela-padrao>
    <AnexoModal
      ref="modal-anexo"
      :form="form"
      @novoArquivo="novoArquivoAnexo"
    />
  </div>
</template>
<script>
import AnexoModal from './modais/AnexoModal.vue';
import _ from 'lodash';
import OrcamentoAnexoService from '@common/services/cadastros/OrcamentoAnexoService';

export default {
  components: {
    AnexoModal,
  },
  props: {
    form: {},
    visualizacao: { type: Boolean, default: false },
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'nomeExtensaoArquivo',
            text: this.$t('modulos.orcamentos.tabela.nome'),
            sortable: true,
          },
        ],
      },
    };
  },
  computed: {
    podeExcluir() {
      return this.tabela.selecionados != 0;
    },
  },
  mounted() {
    if (!this.form.anexos) this.form.anexos = [];
    if (!this.form.anexosAdicionados) this.form.anexosAdicionados = [];
    if (!this.form.anexosRemovidos) this.form.anexosRemovidos = [];
    this.buscar(this.form.id);
  },
  methods: {
    buscar(id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrcamentoAnexoService.listar(id)
        .then((res) => {
          res.data.forEach((anexo) => {
            anexo.flagJaExistente = true;
          });
          this.form.anexos = _.cloneDeep(res.data);
          this.atualizarTabela();
        })
        .catch(() => {
          // this.toastAlerta(
          //   this.$t('modulos.orcamentos.erros.orcamento_sem_anexos')
          // );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    abreModalAnexo() {
      this.$refs['modal-anexo'].abrirModal();
    },
    novoArquivoAnexo(arquivo) {
      this.form.anexosAdicionados.push(arquivo);

      this.atualizarTabela();
    },
    atualizarTabela() {
      this.tabela.dados = [...this.form.anexos].concat([
        ...this.form.anexosAdicionados,
      ]);
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          if (element.flagJaExistente) {
            this.form.anexosRemovidos.push(element.id);
            this.form.anexos.splice(this.form.anexos.indexOf(element), 1);
          } else {
            this.form.anexosAdicionados.splice(
              this.form.anexosAdicionados.indexOf(element),
              1
            );
          }
        });
        this.tabela.selecionados = [];
        this.atualizarTabela();
      });
    },
    fazerDownloadArquivo: async function (item) {
      let anexo;
      if (item.anexo) {
        anexo = item.anexo;
      } else {
        await OrcamentoAnexoService.buscar(item.id).then((res) => {
          anexo = res.data.anexo;
        });
      }
      const blob = this.converterBase64toBlob(anexo);
      const url = URL.createObjectURL(blob);
      const elementoProvisorio = document.createElement('a');
      elementoProvisorio.href = url;
      elementoProvisorio.download = item.nomeExtensaoArquivo;
      elementoProvisorio.click();
      elementoProvisorio.remove();
      URL.revokeObjectURL(url);
    },
    converterBase64toBlob(dataURI) {
      var byteString = window.atob(dataURI.split(',')[1]);
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      var arrayBuffer = new ArrayBuffer(byteString.length);
      var arrayInt = new Uint8Array(arrayBuffer);
      for (var i = 0; i < byteString.length; i++) {
        arrayInt[i] = byteString.charCodeAt(i);
      }
      return new Blob([arrayBuffer], { type: mimeString });
    },
  },
};
</script>

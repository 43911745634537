import { render, staticRenderFns } from "./PecasModal.vue?vue&type=template&id=2cf1883d&scoped=true&"
import script from "./PecasModal.vue?vue&type=script&lang=js&"
export * from "./PecasModal.vue?vue&type=script&lang=js&"
import style0 from "./PecasModal.vue?vue&type=style&index=0&id=2cf1883d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cf1883d",
  null
  
)

export default component.exports
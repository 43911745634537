<template>
  <modal-padrao
    ref="modal-visualizar-ultimos-precos"
    max-width="80%"
    :titulo="
      $t(
        'modulos.orcamentos.formulario.itensOrcamento.botoes.ultimos_precos_orcados'
      )
    "
    titulo-cancelar="Fechar"
    :mostra-botao-salvar="false"
  >
    <tabela-padrao
      :mostrar-checkbox="false"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:item.icones="{ item }">
        <div class="d-flex">
          <span class="mt-2">
            <icone-padrao
              v-if="!item.item.tipoServico"
              :icone="'$cogOutline'"
              :tooltip="'Serviço de Peça'"
            />
            <icone-padrao
              v-if="item.item.tipoServico == 'Calibracao'"
              :icone="'$wrenchClock'"
              :tooltip="'Serviço de Calibração'"
            />
            <icone-padrao
              v-if="item.item.tipoServico == 'Manutencao'"
              :icone="'$wrenchCog'"
              :tooltip="'Serviço de Manutenção'"
            />
          </span>
        </div>
      </template>
    </tabela-padrao>
  </modal-padrao>
</template>
<script>
import helpers from '@common/utils/helpers';
import OrcamentosService from '@common/services/cadastros/OrcamentosService';
export default {
  props: {
    form: {},
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'icones',
            text: '',
          },
          {
            value: 'codigo',
            text: this.$t('modulos.orcamentos.tabela.codigo'),
            sortable: true,
            width: '150px',
          },
          {
            value: 'nome',
            text: this.$t('modulos.orcamentos.tabela.nome'),
            sortable: true,
          },
          {
            value: 'orcamento',
            text: this.$t('modulos.orcamentos.tabela.orcamento'),
            sortable: true,
          },
          {
            value: 'data',
            text: this.$t('modulos.orcamentos.tabela.data'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'valor',
            text: this.$t('modulos.orcamentos.tabela.valor'),
            sortable: true,
            formatter: (v) => helpers.formatarMoeda(v),
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  methods: {
    abrirModal: function () {
      this.$refs['modal-visualizar-ultimos-precos'].abrirModal();
      this.buscarRequisitosCliente();
    },
    buscarRequisitosCliente: function () {
      let params = {
        servicosId: [],
        pecasId: [],
      };

      this.form.orcamentoItem.map((item) => {
        item.orcamentoItemDetalhe.map((itemDetalhe) => {
          if (itemDetalhe.tipo == 'serviço') {
            params.servicosId.push(itemDetalhe.idItem);
          } else {
            params.pecasId.push(itemDetalhe.idItem);
          }
        });
      });

      this.$store.dispatch('Layout/iniciarCarregamento');
      OrcamentosService.buscaUltimosPrecos(
        this.form.participanteClienteId,
        params
      )
        .then((res) => {
          if (res?.data) this.tabela.dados = res.data;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
<style>
.accordion-padrao {
  color: #0d47a1;
}
</style>

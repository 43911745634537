<template>
  <modal-padrao
    ref="modal-servico-manutencao"
    max-width="80%"
    :titulo="$t('modulos.orcamentos.modais.servico_manutencao.titulo')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo-cancelar="$t('geral.botoes.cancelar')"
    @ok="closeModal"
  >
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      class="mt-2"
      filtros-com-colunas
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="false"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      ajustar-altura-linha
      @paginar="listarRegistros"
      @filtrarGlobal="listarRegistros"
      @filtroGlobal="(v) => (filtroAplicado.filter = v || '')"
    >
      <template v-slot:faixa="{ slotProps }">
        <span
          v-if="
            slotProps.data.amplitudeMinima &&
              slotProps.data.amplitudeMaxima &&
            slotProps.data?.unidadeMedida.nome
          "
        >
          {{ slotProps.data?.amplitudeMinima }} a
          {{ slotProps.data?.amplitudeMaxima }}
          {{ slotProps.data?.unidadeMedida.nome }}
        </span>
      </template>
    </tabela-padrao-prime-vue>
  </modal-padrao>
</template>

<script>
import ServicoService from '@/common/services/cadastros/ServicoService';
import { cloneDeep } from 'lodash';
export default {
  name: 'ServicoCalibracaoModal',
  props: {
    instrumentosIds: {
      type: Array,
      default: () => [],
    },
    localExecucaoId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      idsJaSelecionados: [],
      filtroAplicado:{},
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t(
              'modulos.orcamentos.modais.servico_manutencao.codigo'
            ),
            sortable: false,
          },
          {
            value: 'nome',
            text: this.$t('modulos.orcamentos.modais.servico_manutencao.nome'),
            sortable: false,
          },
          {
            value: 'faixa',
            text: this.$t('modulos.orcamentos.modais.servico_manutencao.faixa'),
            sortable: false,
          },
          {
            value: 'localExecucao.nome',
            text: this.$t('modulos.orcamentos.modais.servico_manutencao.local'),
            sortable: false,
          },
          {
            value: 'laboratorio.nome',
            text: this.$t(
              'modulos.orcamentos.modais.servico_manutencao.laboratorio'
            ),
            sortable: false,
          },
          {
            value: 'valorServico',
            text: this.$t('modulos.orcamentos.modais.servico_manutencao.valor'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  methods: {
    abrirModal: function (selecionados) {
      this.idsJaSelecionados = cloneDeep(selecionados);
      this.tabela.selecionados = [];
      this.$refs['modal-servico-manutencao'].abrirModal();
      this.listarRegistros();
      if (this.idsJaSelecionados.length > 0) {
        this.tabela.selecionados = cloneDeep(selecionados);
      }
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado
      };
      let instrumentosIds = this.instrumentosIds
        .map((id) => {
          return `InstrumentoId=${id}`;
        })
        .join('&');

      if (this.localExecucaoId && instrumentosIds?.length == 0) {
        parametros.LocalExecucaoId = this.localExecucaoId;
      }

      ServicoService.buscarTipoServico(
        parametros,
        'Manutencao',
        instrumentosIds
      ).then((response) => {
        this.tabela.dados = response.data.items;
        this.tabela.quantidadeItens = response.data.totalItems;
        this.tabela.quantidadePaginas = response.data.totalPages;
      });
    },
    closeModal() {
      const itensParaRemover = [];
      let itensParaAdicionar = [];
      this.idsJaSelecionados.forEach((itemSelecionado) => {
        const item = this.tabela.selecionados.find(
          (item) => item.id === itemSelecionado.id
        );
        if (!item) {
          itensParaRemover.push(itemSelecionado);
        }
      });

      if (itensParaRemover.length > 0) {
        this.$emit('remover-servicos', itensParaRemover);
      }

      itensParaAdicionar = this.tabela.selecionados.filter((item) => {
        return !this.idsJaSelecionados.find(
          (itemSelecionado) => itemSelecionado.id === item.id
        );
      });

      this.$emit('close-modal-add', {
        type: '',
        selected: itensParaAdicionar,
      });
      this.$refs['modal-servico-manutencao'].fecharModal();
    },
  },
  // Other component options here
};
</script>

<style scoped>
/* Your component's styles here */
</style>

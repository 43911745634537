<template>
  <div>
    <v-form ref="form">
      <div class="row mt-2">
        <input-cliente
          v-model="form.participanteClienteNome"
          class="col-12 col-md-4"
          :label="$t('modulos.orcamentos.formulario.cliente')"
          obrigatorio
          :disabled="
            visualizacao ||
            !!form.ordemServicoCodigo ||
            !$store.getters['Autenticacao/participanteLogadoConta']
          "
        />
        <input-text
          v-model="form.ordemServicoCodigo"
          class="col-12 col-md-2"
          :label="$t('modulos.orcamentos.formulario.cod_os')"
          disabled
        />
        <input-select
          v-model="form.participanteClienteContatoId"
          class="col-12 col-md-2"
          :label="$t('modulos.orcamentos.formulario.aos_cuidados_de')"
          :options="opcoes.aosCuidadosDe"
          :loading="loadingAosCuidadosDe"
          :disabled="visualizacao"
        />
        <input-text
          v-model="form.responsavelUsuarioNome"
          class="col-12 col-md-4"
          :label="$t('modulos.orcamentos.formulario.responsavel')"
          disabled
        />
      </div>
      <div class="row">
        <input-date
          v-model="form.dataEmissao"
          class="col-12 col-md-2"
          :label="$t('modulos.orcamentos.formulario.data_emissao')"
          obrigatorio
          :disabled="visualizacao || !!form.ordemServicoCodigo"
        />
        <input-text
          v-model="form.prazoGarantia"
          class="col-12 col-md-2"
          :label="$t('modulos.orcamentos.formulario.garantia_dias')"
          type="number"
          obrigatorio
          :disabled="visualizacao"
        />
        <input-text
          v-model="form.validadeProposta"
          class="col-12 col-md-2"
          :label="$t('modulos.orcamentos.formulario.validade_dias')"
          type="number"
          :disabled="visualizacao"
        />
        <input-text
          v-model="form.participanteFornecedorNome"
          class="col-12 col-md-3"
          :label="$t('modulos.orcamentos.formulario.fornecedor')"
          disabled
          @click="buscaParticipante('fornecedorParticipante')"
        >
          <template #append>
            <v-icon @click="buscaParticipante('fornecedorParticipante')">
              $search
            </v-icon>
          </template>
        </input-text>
        <input-select
          v-model="form.localExecucaoId"
          class="col-12 col-md-3"
          :label="$t('modulos.orcamentos.formulario.local')"
          :options="opcoes.localExecucao"
          :loading="loadingLocalExecucao"
          obrigatorio
          :disabled="visualizacao || !!form.ordemServicoCodigo"
        />
        <!-- @change="changeLocalExecucao" -->
      </div>
      <div class="row">
        <input-radio
          v-model="form.flagCondicaoPagamentoPorContrato"
          class="col-3"
          :divider="false"
          :options="opcoes.booleano"
          :label="
            $t('modulos.orcamentos.formulario.condicao_pagamento_contrato')
          "
          :disabled="visualizacao || !!form.ordemServicoCodigo"
        />
        <!-- <input-text
          v-model="form.numeroParcela"
          type="number"
          class="col-12 col-md-2"
          :label="$t('modulos.orcamentos.formulario.parcelas')"
          obrigatorio
          :disabled="visualizacao"
        /> -->
        <!-- <input-select
          v-model="form.condicaoPagamentoId"
          class="col-12 col-md-3"
          :label="$t('modulos.orcamentos.formulario.condicao_pagamento')"
          :options="opcoes.condicaoPagamento"
          :loading="loadingCondicaoPagamento"
          :disabled="form.flagCondicaoPagamentoPorContrato || visualizacao"
          :obrigatorio="!form.flagCondicaoPagamentoPorContrato"
        /> -->
        <input-condicao-pagamento
          v-model="form.condicaoPagamentoId"
          class="col-12 col-md-3"
          :label="$t('modulos.orcamentos.formulario.condicao_pagamento')"
          placeholder="Selecione"
          :disabled="form.flagCondicaoPagamentoPorContrato || visualizacao"
          :obrigatorio="!form.flagCondicaoPagamentoPorContrato"
          :multiplos="false"
        />
        <input-text
          v-model="form.numeroPedidoCompra"
          class="col-12 col-md-3"
          :label="$t('modulos.orcamentos.formulario.pedido_compra')"
          :disabled="visualizacao"
        />
        <input-select
          v-model="form.formaSolicitacaoOrcamento"
          class="col-12 col-md-3"
          :label="$t('modulos.orcamentos.formulario.como_solicitado')"
          :options="opcoes.formaSolicitacaoOrcamento"
          :disabled="visualizacao"
        />
      </div>
      <div class="row mb-12">
        <input-radio
          v-model="form.tipoDesconto"
          class="col-4"
          :divider="false"
          :options="opcoes.tipoDesconto"
          :label="$t('modulos.orcamentos.formulario.tipo_desconto')"
          :disabled="visualizacao"
        />
        <input-text
          v-model="form.observacao"
          class="col-8"
          :label="$t('modulos.orcamentos.formulario.observacao')"
          :disabled="visualizacao || !!form.ordemServicoCodigo"
        />
      </div>
    </v-form>
  </div>
</template>
<script>
import { DropdownModel } from '@common/models/geral/DropdownModel';
import UtilsService from '@common/services/UtilsService';
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
import helpers from '@common/utils/helpers';
import { InputCliente, InputCondicaoPagamento } from '@components/inputs';
export default {
  components: {
    InputCliente,
    InputCondicaoPagamento,
  },
  props: {
    form: {},
    prazoGarantia: { type: Number, default: null },
    visualizacao: { type: Boolean, default: false },
    validadeProposta: { type: Number, default: null },
  },
  data() {
    return {
      dadosRequisitosClienteFormatado: null,
      showModalColaboradorResponsavel: false,
      loadingAosCuidadosDe: false,
      loadingLocalExecucao: false,
      loadingCondicaoPagamento: false,
      showModalCliente: false,
      flagCliente: false,
      flagFornecedor: false,
      participanteSelecionado: null,
      tipoParticipanteModal: 'clienteParticipante',
      ignoreWatchCliente: false,
      watchs: {
        tipoDesconto: null,
        mudarLocalExecucao: null,
        alterarCliente: null,
      },
      opcoes: {
        aosCuidadosDe: [],
        localExecucao: [],
        condicaoPagamento: [],
        tipoPessoa: helpers.TipoPessoaEnum,
        papelFornecedor: helpers.PapelFornecedorEnum,
        tipoDesconto: helpers.TipoDescontoEnum,
        diasColeta: helpers.DiasColetaEnum,
        booleano: helpers.BoleanoEnum,
        formaSolicitacaoOrcamento: helpers.FormaSolicitacaoOrcamentoEnum.sort(
          helpers.ordenarDropdown
        ),
      },
    };
  },

  watch: {
    'form.flagCondicaoPagamentoPorContrato'(valor) {
      if (valor) this.form.condicaoPagamentoId = null;
    },
    'form.participanteClienteId'(valor) {
      if (valor) {
        this.listaAosCuidadosDe(valor);
      }
    },
    prazoGarantia() {
      if (this.prazoGarantia) {
        this.form.prazoGarantia = this.prazoGarantia;
        this.form.validadeProposta = this.validadeProposta;
      }
    },
  },

  mounted() {
    this.form.responsavelUsuarioNome = localStorage.getItem('usuarioNome');

    this.listarLocalExecucao();
    // this.listarCondicaoPagamento();
    if (!this.form.id) {
      this.form.prazoGarantia = this.prazoGarantia;
      this.form.flagEmElaboracao = false;
      this.form.numeroParcela = 1;
      this.form.flagCondicaoPagamentoPorContrato = false;
      this.form.formaSolicitacaoOrcamento = 1;
      this.form.validadeProposta = this.validadeProposta;
      this.form.dataEmissao = new Date();
      this.form.tipoDesconto = 'Percentual';
    }
  },
  methods: {
    buscarParticipanteLogado: async function (id) {
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Buscando participante'
      );
      await ParticipanteService.buscar(id)
        .then((res) => {
          if (!this.form.id) {
            this.form.participanteClienteNome = new DropdownModel(
              res.data,
              'apelido'
            );
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    setarWatcherDesconto() {
      this.watchs.tipoDesconto = this.$watch('form.tipoDesconto', (n, o) => {
        this.confirmar(
          this.$t('modulos.orcamentos.tipo_desconto'),
          this.$t('modulos.orcamentos.alerta_tipo_desconto')
        )
          .then(async () => {
            this.zerarDescontoItens();
            await this.$nextTick();
            this.calculaValorFinal();
          })
          .catch(() => {
            this.watchs.tipoDesconto();
            this.form.tipoDesconto = o;
            this.setarWatcherDesconto();
          });
      });

      this.watchs.mudarLocalExecucao = this.$watch(
        'form.localExecucaoId',
        (n, o) => {
          if (!o) return;
          this.confirmar(
            this.$t('modulos.orcamentos.formulario.local'),
            this.$t('modulos.orcamentos.alerta_local_execucao')
          ).then(async () => {
            this.form.localExecucaoId = n;
            this.form.orcamentoItem.forEach((item) => {
              item.descricao = '';
              item.valorUnitario = 0;
              item.valorTotal = 0;
              item.valorFrete = 0;
              item.outrasDespesas = 0;
              item.desconto = 0;
              item.orcamentoItemDetalhe = [];
            });
          });
        }
      );
      this.watchs.alterarCliente = this.$watch(
        'form.participanteClienteNome',
        (newCustomer, oldCustomer) => {
          if (!oldCustomer) return;
          if (this.ignoreWatchCliente) return (this.ignoreWatchCliente = false);
          this.confirmar(
            this.$t('modulos.orcamentos.formulario.cliente'),
            this.$t('modulos.orcamentos.alerta_cliente')
          )
            .then(async () => {
              this.form.participanteClienteNome = newCustomer;
              this.form.participanteClienteContatoId = null;
              this.form.orcamentoItem = [];
              // AQUI
              await this.listaAosCuidadosDe(newCustomer.value);
              await this.buscaRequisitos(newCustomer.value);
            })
            .catch(() => {
              this.ignoreWatchCliente = true;
              this.form.participanteClienteNome = oldCustomer;
            })
            .finally(() => {
              this.calculaValorFinal();
            });
        }
      );
    },
    zerarDescontoItens() {
      this.form.orcamentoItem.forEach((item) => {
        item.desconto = 0;
        item.orcamentoItemDetalhe.forEach((servicoPeca) => {
          servicoPeca.desconto = 0;
        });
      });
    },
    resetValores() {
      this.form.valorTotalItens = 0;
      this.form.valorTotalDesconto = 0;
      this.form.valorTotalFrete = 0;
      this.form.valorTotalOutrosDespesas = 0;
    },
    calculaValorFinal() {
      if (this.form.orcamentoItem.length) {
        this.resetValores();
        this.form.orcamentoItem.map((item) => {
          if (!item.desconto) item.desconto = 0;
          if (this.form.tipoDesconto != 'Percentual') {
            item.valorTotal = parseFloat(item.valorTotal);
            item.valorFrete = parseFloat(item.valorFrete);
            item.outrasDespesas = parseFloat(item.outrasDespesas);
            item.desconto = parseFloat(item.desconto);
            this.form.valorTotalItens +=
              Number(item.valorTotal) +
              Number(item.valorFrete) +
              Number(item.outrasDespesas) -
              Number(item.desconto);
          } else {
            item.valorTotal = parseFloat(item.valorTotal);
            item.valorFrete = parseFloat(item.valorFrete);
            item.outrasDespesas = parseFloat(item.outrasDespesas);
            item.desconto = parseFloat(item.desconto);
            this.descontoPercentual = item.desconto;
            if (item.desconto) {
              this.descontoPercentual = item.valorTotal * (item.desconto / 100);
            }
            this.form.valorTotalItens +=
              Number(item.valorTotal) +
              Number(item.valorFrete) +
              Number(item.outrasDespesas) -
              Number(this.descontoPercentual);
          }

          this.form.valorTotalDesconto += parseFloat(item.desconto);
          this.form.valorTotalFrete += parseFloat(item.valorFrete);
          this.form.valorTotalOutrosDespesas += parseFloat(item.outrasDespesas);
        });
      } else {
        this.resetValores();
      }
    },
    listarLocalExecucao: function () {
      this.loadingLocalExecucao = true;
      UtilsService.buscaLocalExecucao()
        .then((res) => {
          this.opcoes.localExecucao = new DropdownModel(res.data);
        })
        .finally(() => {
          this.loadingLocalExecucao = false;
        });
    },
    listarCondicaoPagamento: function () {
      this.loadingCondicaoPagamento = true;
      UtilsService.buscaCondicaoPagamento()
        .then((res) => {
          res?.data?.items?.map((item) => {
            this.opcoes.condicaoPagamento.push({
              text: item.nome,
              value: item.id,
            });
          });
        })
        .finally(() => {
          this.loadingCondicaoPagamento = false;
        });
    },
    buscaParticipante(origin) {
      this.tipoParticipanteModal = origin;
      if (origin == 'clienteParticipante') {
        this.participanteSelecionado = this.form.participanteClienteId;
        this.flagCliente = true;
        this.flagFornecedor = false;
      } else {
        this.participanteSelecionado = this.form.participanteFornecedorId;
        this.flagCliente = false;
        this.flagFornecedor = true;
      }

      this.showModalCliente = true;
    },
    // manipulaClienteFornecedor(dadosClienteSelecionado = "", type = "") {
    //   this.showModalCliente = false;
    //   if (
    //     type === "add" &&
    //     this.tipoParticipanteModal == "clienteParticipante"
    //   ) {
    //     this.listaAosCuidadosDe(dadosClienteSelecionado.selected[0].id);
    //     this.buscaRequisitos(dadosClienteSelecionado.selected[0].id);
    //     this.form.participanteClienteId = dadosClienteSelecionado.selected[0].id;
    //     this.participanteSelecionado = dadosClienteSelecionado.selected[0].id;
    //     this.form.participanteClienteNome = dadosClienteSelecionado.selected[0].nome;
    //   } else if (
    //     type === "add" &&
    //     this.tipoParticipanteModal == "fornecedorParticipante"
    //   ) {
    //     this.form.participanteFornecedorId = dadosClienteSelecionado.selected[0].id;
    //     this.participanteSelecionado = dadosClienteSelecionado.selected[0].id;
    //     this.form.participanteFornecedorNome = dadosClienteSelecionado.selected[0].nome;
    //   }
    // },
    async buscaRequisitos(id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.dadosRequisitosClienteFormatado = null;
      await ParticipanteService.buscaRequisitosParticipante(
        id,
        'tipoRequisito=6&tipoRequisito=7'
      )
        .then((res) => {
          let dados = res?.data;
          this.dadosRequisitosClienteFormatado = res?.data;

          if (dados.length) {
            this.$refs['modal-requisito']?.abrirModal();
            this.$emit('dados-requisito', dados);
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    async listaAosCuidadosDe(id) {
      this.loadingAosCuidadosDe = true;
      await ParticipanteService.buscaParticipantesContatosPorId(id)
        .then((res) => {
          if (res?.data) {
            res?.data?.map((item) => {
              this.opcoes.aosCuidadosDe.push({
                text: item.nome,
                value: item.id,
              });
            });
          } else this.opcoes.aosCuidadosDe = [];
        })
        .finally(() => {
          this.loadingAosCuidadosDe = false;
        });
    },
    buscaColaboradorResponsavel() {
      this.showModalColaboradorResponsavel = true;
    },
    manipulaColaboradorResponsavel(dadosClienteSelecionado = '', type = '') {
      this.showModalColaboradorResponsavel = false;

      if (type === 'add') {
        this.form.colaboradorId = dadosClienteSelecionado.selected[0].id;
        this.form.colaboradorNome = dadosClienteSelecionado.selected[0].nome;
      }
    },
    changeLocalExecucao(novoValor, valorAntigo) {
      if (this.form.id)
        this.confirmar(
          this.$t('modulos.orcamentos.formulario.local'),
          this.$t('modulos.orcamentos.alerta_local_execucao')
        )
          .then(async () => {
            this.form.localExecucaoId = novoValor;
            this.form.orcamentoItem.forEach((item) => {
              item.orcamentoItemDetalhe = [];
            });
          })
          .catch(() => {
            this.form.localExecucaoId = valorAntigo;
          });
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex mb-8">
      <div>
        <span class="titulo-pagina">
          {{ tituloFormulario }}
        </span>
        <div v-if="form.id && form.codigo">
          <v-chip
            class="mr-1"
            small
          >
            Orçamento: {{ `${form.codigo}` }}
          </v-chip>
        </div>
      </div>
    </div>

    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab v-show="!somenteComentario">
        {{ $t('modulos.orcamentos.formulario.abas.dados_orcamento') }}
      </v-tab>
      <v-tab v-show="!somenteComentario">
        {{ $t('modulos.orcamentos.formulario.abas.itens_orcamento') }}
      </v-tab>
      <v-tab v-show="!somenteComentario">
        {{ $t('modulos.orcamentos.formulario.abas.mensagens') }}
      </v-tab>
      <v-tab :disabled="!id">
        {{ $t('modulos.orcamentos.formulario.abas.comentarios') }}
      </v-tab>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="v-tab"
            v-on="!form.id ? on : null"
          >
            <v-tab
              v-show="!somenteComentario"
              :disabled="!form.id"
              v-bind="attrs"
            >
              {{ $t('modulos.orcamentos.formulario.abas.anexos') }}
            </v-tab>
          </div>
        </template>
        <span>{{
          $t('modulos.orcamentos.formulario.abas.anexos_bloqueado')
        }}</span>
      </v-tooltip>
      <v-tab v-if="!somenteComentario">
        {{ $t('modulos.orcamentos.formulario.abas.requisitos') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="indiceAba">
      <v-tab-item v-show="!somenteComentario">
        <dados-orcamento
          ref="dados-orcamento"
          :form="form"
          :prazo-garantia="prazoGarantia"
          :validade-proposta="validadeProposta"
          :visualizacao="visualizar"
          @dados-requisito="dadosRequisitos"
        />
      </v-tab-item>
      <v-tab-item v-show="!somenteComentario">
        <itens-orcamento
          :visualizacao="visualizar"
          :form="form"
          :prazo-entrega-padrao="prazoEntregaPadrao"
        />
      </v-tab-item>
      <v-tab-item v-show="!somenteComentario">
        <mensagens
          :visualizacao="visualizar"
          :form="form"
          :nova-mensagem="novaMensagem"
        />
      </v-tab-item>
      <v-tab-item>
        <comentario-orcamento
          :visualizacao="somenteComentario ? false : visualizar"
          :form="form"
          :permissoes="permissoes"
          origen="orcamento"
        />
      </v-tab-item>
      <v-tab-item v-show="!somenteComentario">
        <anexos
          :visualizacao="visualizar"
          :form="form"
        />
      </v-tab-item>
      <v-tab-item v-show="!somenteComentario">
        <requisitos
          :form="form"
          :requisitos-cliente="dadosRequisitosClienteFormatado"
        />
      </v-tab-item>
    </v-tabs-items>

    <div
      v-if="indiceAba !== 4 && indiceAba !== 5"
      class="row mb-4 mt-4 pl-2 pr-2"
    >
      <span class="span-total col-12">
        Total R$
        {{
          form.orcamentoItem.length == 0 || form.valorTotalItens == 0
            ? '0,00'
            : form.valorTotalItens | formataValorPrecisaoDois
        }}
      </span>
    </div>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end mt-4">
      <botao-padrao
        v-if="visualizar"
        outlined
        color="secondary"
        class="mr-2"
        @click="voltar"
      >
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
      <botao-padrao
        v-if="!visualizar"
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        v-if="!visualizar"
        class="mr-2"
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
      <botao-padrao
        v-if="visualizar ? false : form.status === 'EmElaboracao' || !form.id"
        @click="confirmacaoFinalizar(true)"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.finalizar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import { OrcamentoModel } from '@common/models/cadastros/OrcamentoModel';
import UtilsService from '@common/services/UtilsService';
import OrcamentoAnexoService from '@common/services/cadastros/OrcamentoAnexoService';
import OrcamentosService from '@common/services/cadastros/OrcamentosService';
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
import helpers from '@common/utils/helpers';
import { Requisitos } from '@components/requisitos';
import { mapGetters } from 'vuex';
import ComentarioOrcamento from '../../../../components/comentario/Comentario.vue';
import Anexos from './components/Anexos.vue';
import DadosOrcamento from './components/DadosOrcamento';
import ItensOrcamento from './components/ItensOrcamento';
import Mensagens from './components/Mensagens.vue';

export default {
  name: 'FormularioOrcamento',
  components: {
    DadosOrcamento,
    ItensOrcamento,
    Requisitos,
    Mensagens,
    ComentarioOrcamento,
    Anexos,
  },
  props: ['id', 'visualizacao'],
  data() {
    return {
      dadosRequisitosClienteFormatado: null,
      validacaoItensOrcamento: false,
      valido: false,
      visualizar: false,
      prazoEntregaPadrao: null,
      prazoGarantia: null,
      indiceAba: 0,
      validadeProposta: 5,
      form: new OrcamentoModel({
        orcamentoItem: [],
      }),
      permissoes: {
        funcionalidade: 'Orcamento',
        acaoComentar: 'Comentar',
        acaoResponder: 'ResponderComentario',
        acaoEncerrar: 'EncerrarComentario',
        acaoExcluir: 'ExcluirComentario',
      },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.visualizacao)
        return this.$t('modulos.orcamentos.titulos.visualizar');
      if (this.id) return this.$t('modulos.orcamentos.titulos.editar');
      return this.$t('modulos.orcamentos.titulos.novo');
    },
    somenteComentario() {
      return this.$route.query?.comentar;
    },
    ...mapGetters('Autenticacao', [
      'participanteLogadoConta',
      'participanteSelecionado',
    ]),
  },
  watch: {
    'form.participanteClienteNome': {
      handler() {
        if (
          !this.form.participanteClienteId &&
          this.form.participanteClienteNome
        ) {
          this.form.participanteClienteId =
            this.form.participanteClienteNome.value;
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    if (this.visualizacao) this.visualizar = true;
    this.listarDadosPadrao();
    if (this.id) {
      this.buscar(this.id);
      helpers.redirecionarSemPermissao(this, 'Orcamento', 'Editar');
    } else {
      this.form.valorTotalItens = 0;
      if (!this.participanteLogadoConta) {
        await this.$refs['dados-orcamento'].buscarParticipanteLogado(
          this.participanteSelecionado
        );
      }
      this.$refs['dados-orcamento'].setarWatcherDesconto();
    }
    helpers.redirecionarSemPermissao(this, 'Orcamento', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    dadosRequisitos: function (dados) {
      this.dadosRequisitosClienteFormatado = dados;
    },
    novaMensagem: function (dados) {
      this.form.orcamentoMensagem = dados;
    },

    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrcamentosService.buscar(this.id)
        .then((res) => {
          this.form = new OrcamentoModel(res.data);
          this.form.ordemServicoCodigo = res.data.ordemServico?.codigo;
          this.form.orcamentoItem.map((item) => {
            item.trocaSpanInputDescricao = false;
            item.trocaSpanInputPrazo = false;
            item.trocaSpanInputQuantidade = false;
            item.trocaSpanInputValorUnitario = false;
            item.trocaSpanInputValorTotal = false;
            item.trocaSpanInputDesconto = false;
            item.trocaSpanInputFrete = false;
            item.trocaSpanInputDespesas = false;
          });
          this.form.participanteClienteId = res.data.participanteCliente?.id;
          this.form.responsavelUsuarioNome = res.data.responsavelUsuario?.nome;
          this.form.ordemServico = res.data.ordemServico
            ? res.data.ordemServico?.codigo
            : null;

          this.buscaRequisitos(this.form.participanteClienteId);

          setTimeout(() => {
            this.form.dataEmissao = res.data.dataEmissao;
          }, 2000);
          this.form.participanteClienteNome =
            res.data.participanteCliente?.apelido;
          this.form.participanteClienteId = res.data.participanteCliente?.id;
          this.form.participanteClienteContatoId =
            res.data.participanteCliente?.contatoId;
          this.form.participanteFornecedorId =
            res.data.participanteFornecedor?.id;
          this.form.participanteFornecedorNome =
            res.data.participanteFornecedor?.nome;
          this.form.localExecucaoId = res.data.localExecucao?.id;
          // this.form.condicaoPagamentoId = res.data.condicaoPagamento?.id;
          this.form.orcamentoItem.map((item) => {
            item.orcamentoItemInstrumento?.map((resItemInstrumento) => {
              if (!item.instrumentosNomes) item.instrumentosNomes = [];
              if (!item.instrumentosSelecionadosColuna)
                item.instrumentosSelecionadosColuna = [];
              if (item.orcamentoItemInstrumento[0].id)
                item.orcamentoItemInstrumento = [];
              item.instrumentosNomes.push(resItemInstrumento.instrumento.nome);
              item.orcamentoItemInstrumento.push({
                nome: resItemInstrumento.instrumento.nome,
                instrumentoId: resItemInstrumento.instrumento.id,
                flagAdicionadoCliente: resItemInstrumento.flagAdicionadoCliente,
              });
            });
          });

          this.form.orcamentoItem?.map((item) => {
            item.orcamentoItemDetalhe?.map((itemDetalhe) => {
              itemDetalhe.codigo = itemDetalhe.servico
                ? itemDetalhe.servico?.codigo
                : itemDetalhe.item?.nome;
              itemDetalhe.tipo = itemDetalhe.servico ? 'serviço' : 'peça';
              itemDetalhe.tipoServico = itemDetalhe.servico
                ? itemDetalhe.servico.tipoServico
                : null;
              itemDetalhe.servicoId = itemDetalhe.servico
                ? itemDetalhe.servico.id
                : null;
              itemDetalhe.idItem = itemDetalhe.servico
                ? itemDetalhe.servico.id
                : itemDetalhe.item.id;
              itemDetalhe.itemId = itemDetalhe.item
                ? itemDetalhe.item.id
                : null;
            });
          });

          this.form.dadosMensagem = this.form.orcamentoMensagem;
          this.form.orcamentoMensagem = [];
          res.data.orcamentoMensagem.forEach((item) => {
            item.mensagemOrcamentoId = item.mensagemOrcamento?.id;
            item.id = item.mensagemOrcamentoId;
            item.mensagemOrcamento.descricaoClassificacao =
              item.mensagemOrcamento?.classificacao;
            this.form.orcamentoMensagem.push({
              ...item,
              ...item?.mensagemOrcamento,
            });
          });
          this.form.orcamentoItem.sort((a, b) => a.numeroItem - b.numeroItem);
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.orcamentos.erros.orcamento_invalido')
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.$refs['dados-orcamento'].setarWatcherDesconto();
          this.valido = true;
          if (this.somenteComentario) this.indiceAba = 3;
        });
    },
    buscaRequisitos(id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ParticipanteService.buscaRequisitosParticipante(
        id,
        'tipoRequisito=6&tipoRequisito=7'
      )
        .then((res) => {
          if (res?.data) this.dadosRequisitos(res?.data);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (this.validacaoCamposItensOrcamento()) return;
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    confirmacaoFinalizar: function (finalizar = false) {
      if (this.validacaoCamposItensOrcamento()) return;
      if (this.id || finalizar) {
        this.confirmar(
          this.$t('geral.botoes.finalizar'),
          this.$t(`modulos.orcamentos.finalizar_orcamento`)
        ).then(() => {
          this.salvar(true);
        });
      } else this.salvar();
    },
    validacaoCamposItensOrcamento() {
      this.validacaoItensOrcamento = false;
      this.form.orcamentoItem.map((item) => {
        if (!item.orcamentoItemDetalhe.length) {
          this.validacaoItensOrcamento = true;
          this.toastAlerta(this.$t(`geral.erros.insira_servico_peca`));
          return;
        }
        if (item.orcamentoItemDetalhe.length) {
          item.orcamentoItemDetalhe.map((itemDetalhe) => {
            if (!itemDetalhe.prazoEntrega) {
              this.validacaoItensOrcamento = true;
              this.toastAlerta(
                this.$t(`geral.erros.prazo_entrega_preencher_item_detalhe`)
              );
            }
            if (!itemDetalhe.quantidade) {
              this.validacaoItensOrcamento = true;
              this.toastAlerta(
                this.$t(`geral.erros.quantidade_preencher_item_detalhe`)
              );
            }
            if (!itemDetalhe.valorUnitario) {
              this.validacaoItensOrcamento = true;
              this.toastAlerta(
                this.$t(`geral.erros.valor_unitario_preencher_item_detalhe`)
              );
            }
            if (!itemDetalhe.valorTotal) {
              this.validacaoItensOrcamento = true;
              this.toastAlerta(
                this.$t(`geral.erros.valor_total_preencher_item_detalhe`)
              );
            }
            if (itemDetalhe.valorFrete == null) {
              itemDetalhe.valorFrete = 0;
            }
            if (itemDetalhe.outrasDespesas == null) {
              itemDetalhe.outrasDespesas = 0;
            }
            if (itemDetalhe.desconto == null) {
              itemDetalhe.desconto = 0;
            }
          });
        }

        if (!item.descricao) {
          this.validacaoItensOrcamento = true;
          this.toastAlerta(this.$t(`geral.erros.descricao_preencher`));
        }
        if (!item.prazoEntrega) {
          this.validacaoItensOrcamento = true;
          this.toastAlerta(this.$t(`geral.erros.prazo_entrega_preencher`));
        }
        if (!item.quantidade) {
          this.validacaoItensOrcamento = true;
          this.toastAlerta(this.$t(`geral.erros.quantidade_preencher`));
        }
        if (!item.valorUnitario) {
          this.validacaoItensOrcamento = true;
          this.toastAlerta(this.$t(`geral.erros.valor_unitario_preencher`));
        }
        if (!item.valorTotal) {
          this.validacaoItensOrcamento = true;
          this.toastAlerta(this.$t(`geral.erros.valor_total_preencher`));
        }
        if (item.valorFrete == null) {
          item.valorFrete = 0;
        }
        if (item.outrasDespesas == null) {
          item.outrasDespesas = 0;
        }
        if (item.desconto == null) {
          item.desconto = 0;
        }
      });

      return this.validacaoItensOrcamento;
    },
    setarFlagsFalsoInstrumentoServico() {
      this.form.orcamentoItem?.forEach((item) => {
        item.orcamentoItemDetalhe?.forEach((itemDetalhe) => {
          itemDetalhe.flagRemovidoCliente = false;
        });
        item.orcamentoItemInstrumento?.forEach((itemInstrumento) => {
          itemInstrumento.flagAdicionadoCliente = false;
        });
      });
    },
    gerenciarMensagens() {
      this.form.orcamentoMensagem?.forEach((mensagem) => {
        mensagem.id = null;
      });
    },
    salvar: async function (finalizado = false) {
      this.gerenciarMensagens();
      if (this.$refs['dados-orcamento']) {
        this.valido = this.$refs['dados-orcamento'].$refs.form.validate();
      }
      if (!this.valido) {
        this.indiceAba = 0;
        return;
      }
      if (this.form.orcamentoItem.length == 0 && this.valido) {
        this.valido = false;
        this.indiceAba = 1;
        this.toastAlerta(this.$t(`geral.erros.insira_itens_orcamento`));
        return;
      }
      if (!this.valido) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      if (!this.form.id && finalizado) this.form.status = 'AguardandoLiberacao';
      else this.form.status = 'EmElaboracao';

      if (this.form.id && finalizado) this.form.flagEmElaboracao = false;
      if (this.form.id && !finalizado) this.form.flagEmElaboracao = true;

      await this.salvarAnexosInseridos();
      this.setarFlagsFalsoInstrumentoServico();
      if (!this.form.participanteClienteId) {
        this.form.participanteClienteId =
          this.form.participanteClienteNome.value;
      }

      OrcamentosService.salvar(this.form.request)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.orcamentos.cadastro_sucesso`));
          this.$router.push({ name: 'orcamentos' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'orcamentos' });
      });
    },
    voltar: function () {
      this.$router.push({ name: 'orcamentos' });
    },
    listarDadosPadrao() {
      UtilsService.buscarDadosPadrao(this.id)
        .then((res) => {
          if (!this.id) {
            this.prazoEntregaPadrao = res.data.prazoEntregaPadrao;
            this.prazoGarantia = res.data.prazoGarantia;
            this.validadeProposta = res.data.prazoOrcamentoContratoPadrao;

          }
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.orcamentos.erros.id_invalido'));
        });
    },
    salvarAnexosInseridos() {
      const promises = [];

      this.form.anexosRemovidos?.forEach((id) => {
        promises.push(OrcamentoAnexoService.excluir(id));
      });
      this.form.anexosAdicionados?.forEach((anexo) => {
        anexo.orcamentoId = this.form.id;
        promises.push(OrcamentoAnexoService.salvar(anexo));
      });

      return promises;
    },
  },
};
</script>

<template>
  <modal-padrao
    ref="modal-peca"
    max-width="80%"
    :titulo="$t('modulos.orcamentos.modais.pecas.titulo')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo-cancelar="$t('geral.botoes.cancelar')"
    @ok="closeModal"
  >
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      class="mt-2"
      filtros-com-colunas
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="false"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      ajustar-altura-linha
      @paginar="listarRegistros"
      @filtrarGlobal="listarRegistros"
      @filtroGlobal="(v) => (filtroAplicado.filter = v || '')"
    />
  </modal-padrao>
</template>

<script>
import ItemService from '@/common/services/cadastros/ItemService';
import { cloneDeep } from 'lodash';

export default {
  name: 'ServicoCalibracaoModal',
  props: {
    instrumentosIds: {
      type: Array,
      default: () => [],
    },
    localExecucaoId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      idsJaSelecionados: [],
      filtroAplicado: { filter: '' },
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.orcamentos.modais.pecas.codigo'),
            sortable: false,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.orcamentos.modais.pecas.descricao'),
            sortable: false,
          },
          {
            value: 'participanteFabricante.nome',
            text: this.$t('modulos.orcamentos.modais.pecas.fabricante'),
            sortable: false,
          },
          {
            value: 'valorVenda',
            text: this.$t('modulos.orcamentos.modais.pecas.valor'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  methods: {
    abrirModal: function (selecionados) {
      this.idsJaSelecionados = cloneDeep(selecionados);
      this.tabela.selecionados = [];
      this.$refs['modal-peca'].abrirModal();
      this.listarRegistros();
      if (this.idsJaSelecionados.length > 0) {
        this.tabela.selecionados = cloneDeep(selecionados);
      }
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        flagEhPeca: true,
        ...this.filtroAplicado,
      };

      if (this.localExecucaoId && this.instrumentosIds?.length == 0) {
        parametros.LocalExecucaoId = this.localExecucaoId;
      }

      let instrumentoString = '';
      if (this.instrumentosIds.length > 0) {
        instrumentoString = '?';
        this.instrumentosIds.map((item) => {
          instrumentoString += `&InstrumentosId=${item}`;
        });
      }
      this.$store.dispatch('Layout/iniciarCarregamento');
      ItemService.buscarPecas(parametros, instrumentoString)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.tipoPecasSelecionados = this.selected;
        });
    },
    closeModal() {
      const itensParaRemover = [];
      let itensParaAdicionar = [];
      this.idsJaSelecionados.forEach((itemSelecionado) => {
        const item = this.tabela.selecionados.find(
          (item) => item.id === itemSelecionado.id
        );
        if (!item) {
          itensParaRemover.push(itemSelecionado);
        }
      });

      if (itensParaRemover.length > 0) {
        this.$emit('remover-servicos', itensParaRemover);
      }

      itensParaAdicionar = this.tabela.selecionados.filter((item) => {
        return !this.idsJaSelecionados.find(
          (itemSelecionado) => itemSelecionado.id === item.id
        );
      });

      this.$emit('close-modal-add', {
        type: '',
        selected: itensParaAdicionar,
      });
      this.$refs['modal-peca'].fecharModal();
    },
  },
  // Other component options here
};
</script>

<style scoped>
/* Your component's styles here */
</style>

<template>
  <modal-padrao
    ref="modal-mensagem"
    :titulo="$t('modulos.orcamentos.formulario.mensagem')"
    max-width="80%"
    :titulo-ok="$t('geral.botoes.confirmar')"
    @ok="salvar"
  >
    <div class="d-flex align-end">
      <div class="row">
        <input-text
          :key="keyAtualiza"
          v-model="filtro.titulo"
          :label="$t('modulos.mensagem_orcamento.tabela.nome')"
          class="col-12 col-md-6"
        />
        <input-text
          v-model="filtro.descricaoClassificacao"
          :label="$t('modulos.mensagem_orcamento.tabela.classificacao')"
          class="col-12 col-md-6"
        />
      </div>
      <botao-padrao
        outlined
        color="secondary"
        class="ml-2"
        @click="filtrar"
      >
        {{ $t('geral.botoes.filtrar') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-model="selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      sem-acoes
      @paginar="listar"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <!-- <template v-slot:expanded-item="{ item }">
        <tr>
          <p class="my-4 mx-2">{{ item.item.descricao }}...</p>
        </tr>
      </template> -->
      <!-- <template v-slot:expanded-item="{ item }">
        <td v-if="item.item.descricao" :colspan="5" style="color: black">
          {{ item.item.descricao }}
        </td>
      </template> -->
      <template v-slot:header.data-table-select>
        <div />
      </template>
      <template v-slot:item.data-table-select="item">
        <div>
          <v-simple-checkbox
            v-model="item.item.isSelected"
            :disabled="!podeSelecionar(item.item.item)"
            @input="selecionarTabela(item.item.item, item.item.isSelected)"
          />
        </div>
      </template>
      <template v-slot:item.info="{ item }">
        <Button
          style="background-color: rgba(0, 255, 255, 0); color: inherit; border-color: rgba(255, 255, 255, 0)"
          type="button"
          icon="pi pi-search"
          aria:haspopup="true"
          aria-controls="overlay_panel"
          @click="toggle($event, item.item)"
        />
      </template>
    </tabela-padrao>
    <OverlayPanel
      id="overlay_panel"
      ref="op"
      append-to="body"
      style="
        width: 800px;
        min-height: 100px;
        overflow-y: scroll;
        max-height: 150px;
      "
    >
      <DataTable
        :value="dadosOverlayPanel"
        striped-rows
        class="p-datatable-sm oculta-thead"
        :rows="4"
      >
        <Column
          field="coluna1"
          header=""
          :styles="{ width: '10%' }"
        >
          <template #body="slotProps">
            <strong>
              {{ slotProps.data.coluna1 }}
            </strong>
          </template>
        </Column>
        <Column
          field="coluna2"
          header=""
        />
      </DataTable>
    </OverlayPanel>
  </modal-padrao>
</template>
<script>
import MensagemOrcamentoService from '@common/services/cadastros/MensagemOrcamentoService';
import _ from 'lodash';
export default {
  props: {
    form: {},
  },
  data() {
    return {
      keyAtualiza: 1,
      expanded: [],
      dadosOverlayPanel: [],
      singleExpand: false,
      filtro: {},
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'titulo',
            text: this.$t('modulos.mensagem_orcamento.tabela.nome'),
            sortable: true,
          },
          {
            value: 'descricaoClassificacao',
            text: this.$t('modulos.mensagem_orcamento.tabela.classificacao'),
            sortable: true,
          },
          {
            value: 'info',
            text: this.$t('modulos.mensagem_orcamento.tabela.mais_informacoes'),
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  mounted() {
    this.listar(this.tabela.paginaAtual);
  },
  methods: {
    toggle(event, info) {
      this.$refs.op.toggle(event);
      this.dadosOverlayPanel = [
        {
          coluna1: 'Descrição:',
          coluna2: info.descricao,
        },
      ];
    },
    abrirModal() {
      this.keyAtualiza++;
      this.$refs['modal-mensagem'].abrirModal();
      this.listar(this.tabela.paginaAtual);
    },
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar(this.tabela.paginaAtual);
    },
    listar: async function (paginaAtual = 1, porPagina = 10) {
      this.selecionados = [];
      await this.verificarObrigatorios();
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      MensagemOrcamentoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.form.orcamentoMensagem?.forEach((mensagem) => {
            this.selecionados.push({
              ...mensagem,
              id: mensagem.mensagemOrcamentoId,
            });
          });
        });
    },
    verificarObrigatorios: async function () {
      const param = {
        amountPerPage: 10000,
        page: 1,
        flagObrigatorio: true,
      };
      await MensagemOrcamentoService.listar(param).then((res) => {
        res.data?.items?.forEach((mensagemObrigatoria) => {
          mensagemObrigatoria.mensagemOrcamentoId = mensagemObrigatoria.id;
          const buscar = this.form.orcamentoMensagem?.find(
            (el) => el.id === mensagemObrigatoria.id
          );
          if (!buscar) {
            this.form.orcamentoMensagem.push(mensagemObrigatoria);
          }
        });
      });
    },
    salvar: function () {
      this.$emit('novaMensagem', this.selecionados);
      this.$refs['modal-mensagem'].fecharModal();
    },
    selecionarTabela: function (item, selecionado) {
      if (!selecionado) {
        this.selecionados.splice(
          this.selecionados.indexOf(
            this.selecionados.find((el) => el.id == item.id)
          ),
          1
        );
      } else {
        this.selecionados.push(item);
      }
    },
    podeSelecionar(item) {
      return !item.flagObrigatorio;
    },
  },
};
</script>

<template>
  <BaseModal
    id="modal"
    :show="showModal"
    :width="1150"
    :height="460"
    close-direction="left"
    :show-footer-actions="false"
    :title="newModalTitle"
    @close-modal="closeModal('close')"
  >
    <template v-slot:content>
      <div>
        <div class="d-flex align-end">
          <input-text
            v-model="tipoServicoManutencao.ocorrencia"
            :placeholder="$t('componentes.input_setor.buscar')"
            class="flex-fill mr-2"
            @enter="filtraTipoServicoManutencao"
          />
          <botao-padrao
            outlined
            color="secondary"
            class="ml-2"
            @click="filtraTipoServicoManutencao"
          >
            {{ $t('geral.botoes.filtrar') }}
          </botao-padrao>
        </div>

        <v-row v-if="tableCliente.items.length" class="mt-0">
          <v-col cols="12" md="12">
            <v-data-table
              id="virtual-scroll-table"
              v-model="tipoServicosManutencaoSelecionados"
              :headers="tableCliente.headers"
              :items="tableCliente.items"
              :items-per-page="tableCliente.metadata.amountPerPage"
              item-key="id"
              show-select
              fixed-header
              height="240"
              hide-default-footer
              :single-select="singleSelect"
              @item-selected="selectSingleItem"
            >
              <template slot="no-data">
                {{ $t('components.baseTable.slots.noData') }}
              </template>

              <!-- eslint-disable vue/valid-v-slot-->
              <template v-slot:item.data-table-select="{ isSelected, select }">
                <v-simple-checkbox
                  data-button="select"
                  color="primary"
                  :value="isSelected"
                  @input="select($event)"
                />
              </template>
              <template v-slot:item.valorServico="{ value }">
                {{ value | formatarValorMoney }}
              </template>

              <template v-slot:item.faixa="{ item }">
                <span v-if="item.unidadeMedida">
                  {{ item.unidadeMedida.nome }} {{ item.amplitudeMinima }} a
                  {{ item.amplitudeMaxima }}
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
      <v-divider class="my-5" />
      <div class="d-flex align-center justify-end mb-2">
        <botao-padrao
          outlined
          color="secondary"
          class="mr-2"
          @click="closeModal('close')"
        >
          <v-icon>$mdiCancel</v-icon>
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>
        <botao-padrao @click="closeModal('add')">
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.confirmar') }}
        </botao-padrao>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import UtilsService from '@common/services/UtilsService';
import helpers from '@common/utils/helpers';

export default {
  name: 'PesquisaTipoServicoManutencaoModal',
  components: {
    BaseModal: () => import('@components/fragments/BaseModal'),
  },
  filters: {
    formatarValorMoney: helpers.formatarMoeda,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
      validator: (value) => {
        return [true, false].includes(value);
      },
    },
    selected: {
      type: Array,
      default: () => {
        return [];
      },
    },
    instrumentosSelecionadosIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    modalType: {
      type: String,
      default: 'clienteParticipante',
      validator: (value) => {
        return ['clienteParticipante', 'solicitanteParticipante'].includes(
          value
        );
      },
    },
    modalTitle: {
      type: String,
      default: '',
    },
    singleSelect: {
      type: Boolean,
      default: true,
      validator: (value) => {
        return [true, false].includes(value);
      },
    },
  },
  data() {
    return {
      tipoServicoManutencao: {
        ocorrencia: '',
      },
      tableCliente: {
        key: 'codigo',
        headers: [
          {
            text: this.$t('componentes.input_instrumento.codigo'),
            sortable: true,
            value: 'codigo',
          },
          {
            text: this.$t('modulos.orcamentos.formulario.especificacao'),
            sortable: true,
            value: 'nome',
          },
          {
            text: this.$t('modulos.orcamentos.formulario.faixa'),
            sortable: true,
            value: 'faixa',
          },
          {
            text: this.$t('modulos.orcamentos.formulario.local'),
            sortable: true,
            value: 'localExecucao.nome',
          },
          {
            text: this.$t('modulos.orcamentos.formulario.laboratorio'),
            sortable: true,
            value: 'laboratorio.nome',
          },
          {
            text: this.$t('modulos.orcamentos.formulario.valor'),
            sortable: true,
            value: 'valorServico',
          },
        ],
        items: [],
        metadata: {
          page: 1,
          totalPages: 1,
          totalItems: null,
          amountPerPage: -1,
        },
      },
      tipoServicosManutencaoSelecionados: [],
    };
  },
  computed: {
    newModalTitle() {
      if (this.modalTitle) return this.modalTitle;

      const complement = this.$t(
        'modulos.orcamentos.formulario.titulo_modal_servicos_manutencao'
      );
      return this.$t(
        `modulos.orcamentos.formulario.titulo_modal_servicos_manutencao`,
        { 0: complement }
      );
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        this.resetModal();
        this.listaServicosManutencao();
      }
    },
  },
  methods: {
    async filtraTipoServicoManutencao() {
      const modalParams = this.tipoServicoManutencao;
      this.tipoServicosManutencaoSelecionados = [];
      await this.listaServicosManutencao(modalParams);
    },
    async selectSingleItem(e) {
      if (!e.value && !this.tableCliente.items.length)
        await this.listaServicosManutencao();
    },
    resetModal() {
      this.tipoServicoManutencao = {
        ocorrencia: '',
      };
      this.tableCliente.items = [];
      this.tipoServicosManutencaoSelecionados = [];
      this.cidadeList = [];
    },
    async listaServicosManutencao(params = {}) {
      let instrumentoString = '';
      if (this.instrumentosSelecionadosIds.length > 0) {
        this.instrumentosSelecionadosIds.map((item) => {
          instrumentoString += `&instrumentoId=${item}`;
        });
      }
      this.$store.dispatch('Layout/iniciarCarregamento');
      UtilsService.listarTipoServicos(params, 2, instrumentoString)
        .then((res) => {
          this.tableCliente.items = res.data.items;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.tipoServicosManutencaoSelecionados = this.selected;
        });
    },
    closeModal(type) {
      if (type === 'close') {
        this.$emit('close-modal');
      } else {
        this.$emit('close-modal-add', {
          type: '',
          selected: this.tipoServicosManutencaoSelecionados,
        });
      }
    },
  },
};
</script>

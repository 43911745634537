<template lang="">
  <modal-padrao
    ref="modal-anexo"
    max-width="60%"
    :titulo="$t('modulos.orcamentos.formulario.abas.anexos')"
    :titulo-ok="$t('geral.botoes.salvar')"
    :titulo-cancelar="$t('geral.botoes.voltar')"
    :ok-desabilitado="!valido"
    @ok="salvar"
  >
    <v-form ref="form" class="row">
      <input-text
        v-model="form.nomeExtensaoArquivo"
        class="col-12 col-md-6"
        :label="$t('modulos.orcamentos.formulario.nome')"
        obrigatorio
      />
      <input-file
        accept="*"
        class="col-12 col-md-6"
        returns="base64"
        :label="$t('modulos.orcamentos.formulario.adicionar_arquivo')"
        obrigatorio
        @clear="desvincularAnexo"
        @handle-upload="handlerUploadInputFile"
      />
    </v-form>
  </modal-padrao>
</template>
<script>
import InputFile from '@/components/inputs/InputFile.vue';
import helpers from '@common/utils/helpers';
export default {
  components: { InputFile },
  data() {
    return {
      form: {},
      valido: false,
    };
  },
  watch: {
    form: {
      handler() {
        if (this.$refs['form']) {
          this.valido = this.$refs.form.validate() && this.form.anexo;
        }
      },
      deep: true,
    },
  },
  methods: {
    desvincularAnexo() {
      this.form.anexo = null;
    },
    abrirModal: function () {
      this.iniciarForm();
      this.$refs['modal-anexo'].abrirModal();
    },
    iniciarForm() {
      this.form = {
        id: helpers.gerarNumeroAleatorio(),
        nomeExtensaoArquivo: null,
        anexo: null,
      };
    },
    handlerUploadInputFile(base64) {
      this.form.anexo = base64;
    },
    salvar() {
      this.$emit('novoArquivo', this.form);
      this.$refs['modal-anexo'].fecharModal();
    },
  },
};
</script>

<template>
  <BaseModal
    id="modal"
    :show="showModal"
    :width="960"
    :height="460"
    close-direction="left"
    :show-footer-actions="false"
    :title="newModalTitle"
    @close-modal="closeModal('close')"
  >
    <template v-slot:content>
      <div>
        <div class="d-flex align-end">
          <input-text
            v-model="pecas.buscaGlobal"
            :placeholder="$t('componentes.input_setor.buscar')"
            class="flex-fill mr-2"
            @enter="filtraPecas"
          />
          <botao-padrao
            outlined
            color="secondary"
            class="ml-2"
            @click="filtraPecas"
          >
            {{ $t('geral.botoes.filtrar') }}
          </botao-padrao>
        </div>

        <v-row v-if="tableCliente.items.length" class="mt-0">
          <v-col cols="12" md="12">
            <v-data-table
              id="virtual-scroll-table"
              v-model="tipoPecasSelecionados"
              :headers="tableCliente.headers"
              :items="tableCliente.items"
              :items-per-page="tableCliente.metadata.amountPerPage"
              item-key="id"
              show-select
              fixed-header
              height="240"
              hide-default-footer
              :single-select="singleSelect"
              @item-selected="selectSingleItem"
            >
              <template slot="no-data">
                {{ $t('components.baseTable.slots.noData') }}
              </template>

              <!-- eslint-disable vue/valid-v-slot-->
              <template v-slot:item.data-table-select="{ isSelected, select }">
                <v-simple-checkbox
                  data-button="select"
                  color="primary"
                  :value="isSelected"
                  @input="select($event)"
                />
              </template>
              <template v-slot:item.valorVenda="{ value }">
                {{ value | formatarValorMoney }}
              </template>

              <template v-slot:item.documento="{ value }">
                {{ value | VMask(formatDocument(value)) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>

      <v-divider class="my-5" />
      <div class="d-flex align-center justify-end mb-2">
        <botao-padrao
          outlined
          color="secondary"
          class="mr-2"
          @click="closeModal('close')"
        >
          <v-icon>$mdiCancel</v-icon>
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>
        <botao-padrao @click="closeModal('add')">
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.confirmar') }}
        </botao-padrao>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import ItemService from '@common/services/cadastros/ItemService';
import helpers from '@common/utils/helpers';

export default {
  name: 'PesquisaPecasModal',
  components: {
    BaseModal: () => import('@components/fragments/BaseModal'),
  },
  filters: {
    formatarValorMoney: helpers.formatarMoeda,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
      validator: (value) => {
        return [true, false].includes(value);
      },
    },
    selected: {
      type: Array,
      default: () => {
        return [];
      },
    },
    instrumentosSelecionadosIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    modalTitle: {
      type: String,
      default: '',
    },
    singleSelect: {
      type: Boolean,
      default: true,
      validator: (value) => {
        return [true, false].includes(value);
      },
    },
  },
  data() {
    return {
      pecas: {
        buscaGlobal: '',
      },
      tableCliente: {
        key: 'codigo',
        headers: [
          {
            text: this.$t('componentes.input_instrumento.codigo'),
            sortable: true,
            value: 'codigo',
          },
          {
            text: this.$t('modulos.orcamentos.formulario.descricao'),
            sortable: true,
            value: 'descricao',
          },
          // {
          //   text: this.$t('modulos.orcamentos.formulario.tipo_instrumento'),
          //   sortable: true,
          //   value: 'tipoInstrumento.nome'
          // },
          {
            text: this.$t('modulos.orcamentos.formulario.fabricante'),
            sortable: true,
            value: 'participanteFabricante.nome',
          },
          {
            text: this.$t('modulos.orcamentos.formulario.qtd'),
            sortable: true,
            value: 'quantidadeEstoque',
          },
          {
            text: this.$t('modulos.orcamentos.formulario.valor'),
            sortable: true,
            value: 'valorVenda',
            formatter: (v) => helpers.formatarMoeda(v),
          },
        ],
        items: [],
        metadata: {
          page: 1,
          totalPages: 1,
          totalItems: null,
          amountPerPage: -1,
        },
      },
      tipoPecasSelecionados: [],
      cidadeList: [],
    };
  },
  computed: {
    newModalTitle() {
      if (this.modalTitle) return this.modalTitle;

      const complement = this.$t(
        'modulos.orcamentos.formulario.titulo_modal_pecas'
      );
      return this.$t(`modulos.orcamentos.formulario.titulo_modal_pecas`, {
        0: complement,
      });
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        this.resetModal();
        this.listaPecas();
      }
    },
  },
  methods: {
    async filtraPecas() {
      const modalParams = this.pecas;
      this.tipoPecasSelecionados = [];
      await this.listaPecas(modalParams);
    },
    async selectSingleItem(e) {
      if (!e.value && !this.tableCliente.items.length) await this.listaPecas();
    },
    resetModal() {
      this.cliente = {
        buscaGlobal: '',
      };
      this.tableCliente.items = [];
      this.tipoPecasSelecionados = [];
      this.cidadeList = [];
    },
    async listaPecas(params = {}) {
      let instrumentoString = '';
      if (this.instrumentosSelecionadosIds.length > 0) {
        instrumentoString = '?';
        this.instrumentosSelecionadosIds.map((item) => {
          instrumentoString += `&instrumentoId=${item}`;
        });
      }
      this.$store.dispatch('Layout/iniciarCarregamento');
      ItemService.buscarPecasGlobal(params, instrumentoString)
        .then((res) => {
          this.tableCliente.items = res.data.items;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.tipoPecasSelecionados = this.selected;
        });
    },
    closeModal(type) {
      if (type === 'close') {
        this.$emit('close-modal');
      } else {
        this.$emit('close-modal-add', {
          type: '',
          selected: this.tipoPecasSelecionados,
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex justify-end">
      <div class="d-flex">
        <botao-padrao
          v-if="form.participanteClienteNome && existeOrcamentoItemDetalhe"
          class="mt-3 mb-3 mr-2"
          @click="abrirModalVisualizarUltimosPrecosOrcados"
        >
          {{
            $t(
              'modulos.orcamentos.formulario.itensOrcamento.botoes.visualizar_ultimos_precos_orcados'
            )
          }}
        </botao-padrao>
        <botao-padrao
          v-if="!visualizacao"
          class="mt-3 mb-3"
          :disabled="!form.participanteClienteNome || !!form.ordemServicoCodigo"
          :tooltip="tooltipAbrirModalInstrumentos"
          @click="adicionaItemOrcamento"
        >
          {{
            $t(
              'modulos.orcamentos.formulario.itensOrcamento.botoes.novoItemOrcamento'
            )
          }}
        </botao-padrao>
      </div>
    </div>

    <v-data-table
      :key="keyAtualiza"
      :headers="tabela.colunas"
      :items="form.orcamentoItem"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="numeroItem"
      :items-per-page="Number(-1)"
      show-expand
      dense
      hide-default-footer
      v-if="form.orcamentoItem.length"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:item.numero="{ item, index }">
        <div
          class="d-flex"
          style="align-items: center"
        >
          <div>
            <dropdown-padrao
              v-if="!visualizacao && !form.ordemServicoCodigo"
              text
              color="secondary"
              style="width: 100% !important"
            >
              <template #botao>
                <v-icon> $dotsVertical </v-icon>
              </template>

              <v-list-item-group style="overflow-y: auto">
                <v-list-item
                  class="d-flex min-height-drop-item"
                  @click="duplicar(item)"
                >
                  {{ $t('geral.botoes.duplicar') }}
                </v-list-item>
                <v-divider />
                <v-list-item
                  class="d-flex min-height-drop-item"
                  @click="excluir(index)"
                >
                  <v-icon>$mdiTrashCanOutline</v-icon>
                  {{ $t('geral.botoes.excluir') }}
                </v-list-item>
              </v-list-item-group>
            </dropdown-padrao>
          </div>
          <icone-padrao
            v-if="item.orcamentoItemInstrumento && !form.ordemServicoCodigo"
            :icone="definirIconeAdicionadoRemovido(item).icon"
            :classe-icone="definirIconeAdicionadoRemovido(item).color"
            size="20"
            :tooltip="definirIconeAdicionadoRemovido(item).tooltip"
          />
          <icone-padrao
            v-if="!existeItemReprovado(item) && visualizacao"
            :icone="'$fileCheckOutline'"
            :tooltip="'Aprovado'"
            classe-icone="ml-2 primary-success-700--text"
          />
          <icone-padrao
            v-else-if="item.quantidadeAprovada==0 && visualizacao"
            :icone="'$fileDocumentRemoveOutline'"
            :tooltip="'Reprovado'"
            classe-icone="ml-2 danger--text"
          />

          <icone-padrao
            v-else-if="existeItemReprovado(item) && visualizacao"
            :icone="'$mdiFileCheck'"
            :tooltip="'Aprovado Parcialmente'"
            classe-icone="ml-2"
          />
          <div class="ml-2">
            {{ item.numeroItem }}
          </div>
        </div>
      </template>
      <template v-slot:item.instrumento="{ item, index }">
        <div class="d-flex">
          <div
            v-if="!visualizacao && !form.ordemServicoCodigo"
            style="align-self: center"
            class="mr-2 d-flex w-40"
          >
            <v-icon
              size="22"
              :disabled="!!item.orcamentoItemDetalhe.length"
              @click="abreModalInstrumentos(index)"
            >
              $search
            </v-icon>
          </div>
          <div class="d-flex">
            <accordion-tabela v-if="item.instrumentosNomes?.length > 1">
              <template v-slot:header="{ on }">
                <div
                  class="d-flex"
                  style="width: 125px"
                  v-on="item.instrumentosNomes[0] ? on : null"
                >
                  <span>{{ item.instrumentosNomes?.length }} </span>
                  <span> {{ '-Instrumentos' }} </span>
                </div>
              </template>
              <div
                v-for="(nome, indexInstrumento) in item.instrumentosNomes"
                :key="nome"
              >
                <v-chip
                  :close="!visualizacao && !form.ordemServicoCodigo"
                  label
                  outlined
                  color="cinza200"
                  close-icon="$closeBoxOutline"
                  @click:close="removerInstrumento(index, indexInstrumento)"
                >
                  <span>{{ nome }}</span>
                  <v-icon
                    class="ml-2"
                    size="18"
                    @click="abreModalEditarInstrumento(item, nome)"
                  >
                    $edit
                  </v-icon>
                </v-chip>
              </div>
            </accordion-tabela>

            <div v-if="item.instrumentosNomes?.length == 1">
              <div
                v-for="(nome, indexInstrumento) in item.instrumentosNomes"
                :key="nome"
              >
                <v-chip
                  :close="!visualizacao && !form.ordemServicoCodigo"
                  label
                  outlined
                  color="cinza200"
                  close-icon="$closeBoxOutline"
                  @click:close="removerInstrumento(index, indexInstrumento)"
                >
                  <span>{{ nome }}</span>
                  <v-icon
                    class="ml-2"
                    size="18"
                    @click="abreModalEditarInstrumento(item, nome)"
                  >
                    $edit
                  </v-icon>
                </v-chip>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:item.descricao="{ item }">
        <v-tooltip
          v-if="item.descricao"
          top
          class="pl-0"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <div
                v-if="!item.trocaSpanInputDescricao"
                class="col-12 cursor-pointer texto-descricao"
                @click="trocaSpanColocaFocoDescricao(item.numeroItem)"
              >
                <span v-html="item.descricao" />
              </div>
            </div>
          </template>
          <span>{{ item.descricao }}</span>
        </v-tooltip>
        <div v-if="!item.descricao">
          <div class="descricao-obrigatorio">
            <span class="descricao-obrigatorio-asterisco">*</span>
            <div
              v-if="!item.trocaSpanInputDescricao"
              class="col-12 cursor-pointer texto-descricao"
              @click="trocaSpanColocaFocoDescricao(item.numeroItem)"
            >
              <span v-html="item.descricao" />
            </div>
          </div>
          <span>{{ item.descricao }}</span>
        </div>
        <input-textarea
          v-if="item.trocaSpanInputDescricao && !visualizacao"
          :ref="'inputDescricao' + item.numeroItem"
          v-model="item.descricao"
          style="width: 100%; min-width: 240px"
          class="mb-2 ml-2"
          @blur="atualizaSpanInputDescricao(item.numeroItem)"
        />
      </template>
      <template v-slot:item.prazoEntrega="{ item }">
        <div
          class="col-12"
          style="text-align-last: center"
        >
          <span>{{ item.prazoEntrega }}</span>
        </div>
      </template>
      <template v-slot:item.qtd="{ item }">
        <div
          class="col-12"
          style="text-align-last: center"
          @click="trocaSpanColocaFocoQuantidade(item.numeroItem)"
        >
          <span>{{ item.quantidade }}</span>
        </div>
      </template>
      <template v-slot:item.valorUnitario="{ item }">
        <div
          class="col-12"
          style="text-align-last: end; padding-right: 0"
          @click="trocaSpanColocaFocoValorUnitario(item.numeroItem)"
        >
          <span>{{
            item.valorUnitario
              ? item.valorUnitario
              : '0,00' | formataValorPrecisaoDois
          }}</span>
        </div>
      </template>
      <template v-slot:item.desconto="{ item }">
        <div
          class="col-12 cursor-pointer"
          style="text-align-last: end; padding-right: 0"
          @click="trocaSpanColocaFocoDesconto(item.numeroItem)"
        >
          <span>{{
            item.desconto ? item.desconto : '0,00' | formataValorPrecisaoDois
          }}</span>
        </div>
      </template>
      <template v-slot:item.frete="{ item }">
        <div
          class="col-12 cursor-pointer mr-2"
          style="text-align-last: end; padding-right: 0"
          @click="trocaSpanColocaFocoFrete(item.numeroItem)"
        >
          <span>{{
            item.valorFrete
              ? item.valorFrete
              : '0,00' | formataValorPrecisaoDois
          }}</span>
        </div>
      </template>
      <template v-slot:item.despesas="{ item }">
        <div
          class="col-12"
          style="text-align-last: end; padding-right: 0"
          @click="trocaSpanColocaFocoDespesas(item.numeroItem)"
        >
          <span>{{
            item.outrasDespesas
              ? item.outrasDespesas
              : '0,00' | formataValorPrecisaoDois
          }}</span>
        </div>
      </template>

      <template v-slot:item.valorTotal="{ item }">
        <div
          class="col-12"
          style="text-align-last: end; padding-right: 0"
        >
          <span>{{
            item.valorTotal
              ? item.valorTotal
              : '0,00' | formataValorPrecisaoDois
          }}</span>
        </div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{ $t('modulos.orcamentos.formulario.codigo') }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{ $t('modulos.orcamentos.formulario.descricao') }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{ $t('modulos.orcamentos.formulario.tipoCalibracao') }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                    style="text-align: center"
                  >
                    {{ $t('modulos.orcamentos.formulario.prazo') }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                    style="text-align: center"
                  >
                    {{ $t('modulos.orcamentos.formulario.qtd') }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                    style="text-align: center; min-width: 145px"
                  >
                    {{ $t('modulos.orcamentos.formulario.valor_unitario') }}
                  </th>

                  <th
                    class="th-simple-table"
                    scope="col"
                    style="text-align: center; min-width: 130px"
                  >
                    {{
                      form.tipoDesconto == 'Percentual'
                        ? $t(
                          'modulos.orcamentos.formulario.desconto_icone_percent'
                        )
                        : $t('modulos.orcamentos.formulario.desconto')
                    }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                    style="text-align: center"
                  >
                    {{ $t('modulos.orcamentos.formulario.frete') }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                    style="text-align: center"
                  >
                    {{ $t('modulos.orcamentos.formulario.despesas') }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                    style="text-align: center"
                  >
                    {{ $t('modulos.orcamentos.formulario.valor_total') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(
                    instrumento, indexInstrumento
                  ) in item.orcamentoItemDetalhe"
                  :key="instrumento.numeroItem"
                  class="description-tr"
                >
                  <td class="col-2">
                    <div
                      class="d-flex"
                    >
                      <icone-padrao
                        v-if="!form.ordemServicoCodigo"
                        :icone="
                          instrumento.flagRemovidoCliente
                            ? '$closeCircleOutline'
                            : '$successCircleOutline'
                        "
                        :classe-icone="`${
                          instrumento.flagRemovidoCliente
                            ? 'danger--text'
                            : 'primary-success-700--text'
                        } mt-2`"
                        size="20"
                        :tooltip="
                          instrumento.flagRemovidoCliente
                            ? $t('modulos.contrato.item_removido')
                            : $t('modulos.contrato.item_validado')
                        "
                      />
                      <dropdown-padrao
                        v-if="!visualizacao && !form.ordemServicoCodigo"
                        :drop-w100="false"
                        text
                        color="secondary"
                      >
                        <template #botao>
                          <v-icon> $dotsVertical </v-icon>
                        </template>
                        <v-list-item-group style="overflow-y: auto">
                          <v-list-item
                            class="d-flex min-height-drop-item"
                            @click="
                              removerTipoServicosEPecas(
                                item.numeroItem,
                                indexInstrumento
                              )
                            "
                          >
                            {{ $t('geral.botoes.remover') }}
                          </v-list-item>
                        </v-list-item-group>
                      </dropdown-padrao>
                      <span class="mt-2">
                        <icone-padrao
                          v-if="instrumento.quantidade == instrumento.quantidadeAprovada && visualizacao"
                          :icone="'$fileCheckOutline'"
                          :tooltip="'Aprovado'"
                          classe-icone="primary-success-700--text"
                        />
                        <icone-padrao
                          v-else-if="instrumento.quantidade !== instrumento.quantidadeAprovada && visualizacao"
                          :icone="'$fileDocumentRemoveOutline'"
                          :tooltip="'Reprovado'"
                          classe-icone="danger--text"
                        />
                        <icone-padrao
                          v-if="instrumento.itemId || instrumento.item"
                          :icone="'$cogOutline'"
                          :tooltip="'Peça'"
                        />
                        <icone-padrao
                          v-if="instrumento.tipoServico == 'Calibracao'"
                          :icone="'$wrenchClock'"
                          :tooltip="'Serviço de Calibração'"
                        />
                        <icone-padrao
                          v-if="instrumento.tipoServico == 'Manutencao'"
                          :icone="'$wrenchCog'"
                          :tooltip="'Serviço de Manutenção'"
                        />

                        {{ instrumento.codigo }}
                      </span>
                    </div>
                  </td>
                  <td class="col-3">
                    {{ instrumento.descricao }}
                  </td>
                  <td class="col-3">
                    {{ instrumento?.servico?.tipoCalibracao?.nome || instrumento?.tipoCalibracao }}
                  </td>
                  <td class="col-1">
                    <InputNumber
                      :ref="'inputPrazoServico' + instrumento.servicoId"
                      v-model="instrumento.prazoEntrega"
                      input-style="border-radius: 8px; border-color: rgb(117 124 131); max-width: 80px;"
                      class="p-inputtext-sm"
                      :min="0"
                      :disabled="visualizacao"
                      @blur="
                        calcularLinhasItens(
                          item.numeroItem,
                          indexInstrumento,
                          item.orcamentoItemDetalhe
                        )
                      "
                      @focus="selecionarConteudo"
                    />
                  </td>
                  <td class="col-1">
                    <InputNumber
                      :ref="'inputQuantidadeServico' + instrumento.servicoId"
                      v-model="instrumento.quantidade"
                      input-style="border-radius: 8px; border-color: rgb(117 124 131); max-width: 80px;"
                      class="p-inputtext-sm"
                      :min="0"
                      :disabled="form.ordemServicoCodigo || visualizacao"
                      @blur="
                        calcularLinhasItens(
                          item.numeroItem,
                          indexInstrumento,
                          item.orcamentoItemDetalhe
                        )
                      "
                      @focus="selecionarConteudo"
                    />
                  </td>
                  <td class="col-1">
                    <InputNumber
                      :ref="'inputValorUnitarioServico' + instrumento.servicoId"
                      v-model="instrumento.valorUnitario"
                      input-style="border-radius: 8px; border-color: rgb(117 124 131); max-width: 100px;"
                      locale="pt-BR"
                      mode="decimal"
                      :min-fraction-digits="2"
                      class="p-inputtext-sm"
                      :min="0"
                      :disabled="visualizacao"
                      @blur="
                        calcularLinhasItens(
                          item.numeroItem,
                          indexInstrumento,
                          item.orcamentoItemDetalhe
                        )
                      "
                      @focus="selecionarConteudo"
                    />
                  </td>

                  <td class="col-1">
                    <InputNumber
                      :ref="'inputDescontoServico' + instrumento.servicoId"
                      v-model="instrumento.desconto"
                      input-style="border-radius: 8px; border-color: rgb(117 124 131); max-width: 80px;"
                      locale="pt-BR"
                      :min-fraction-digits="2"
                      class="p-inputtext-sm"
                      :disabled="visualizacao"
                      :min="0"
                      @blur="
                        calcularLinhasItens(
                          item.numeroItem,
                          indexInstrumento,
                          item.orcamentoItemDetalhe
                        )
                      "
                      @focus="selecionarConteudo"
                    />
                  </td>
                  <td class="col-1">
                    <InputNumber
                      :ref="'inputFreteServico' + instrumento.servicoId"
                      v-model="instrumento.valorFrete"
                      input-style="border-radius: 8px; border-color: rgb(117 124 131); max-width: 80px;"
                      locale="pt-BR"
                      :min-fraction-digits="2"
                      class="p-inputtext-sm"
                      :disabled="visualizacao"
                      :min="0"
                      @blur="
                        calcularLinhasItens(
                          item.numeroItem,
                          indexInstrumento,
                          item.orcamentoItemDetalhe
                        )
                      "
                      @focus="selecionarConteudo"
                    />
                  </td>
                  <td class="col-1">
                    <InputNumber
                      :ref="'inputDespesasServico' + instrumento.servicoId"
                      v-model="instrumento.outrasDespesas"
                      input-style="border-radius: 8px; border-color: rgb(117 124 131); max-width: 80px;"
                      locale="pt-BR"
                      :min-fraction-digits="2"
                      class="p-inputtext-sm"
                      :disabled="visualizacao"
                      :min="0"
                      @blur="
                        calcularLinhasItens(
                          item.numeroItem,
                          indexInstrumento,
                          item.orcamentoItemDetalhe
                        )
                      "
                      @focus="selecionarConteudo"
                    />
                  </td>
                  <td class="col-1">
                    <div
                      style="text-align-last: end"
                      class="col-12"
                    >
                      <span>{{
                        instrumento.valorTotal
                          ? instrumento.valorTotal
                          : '0,00' | formataValorPrecisaoDois
                      }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div
            v-if="!visualizacao && !form.ordemServicoCodigo"
            class="row"
          >
            <div class="d-flex mt-1 mb-1 col-7">
              <dropdown-padrao
                text
                color="secondary"
                :drop-w100="false"
              >
                <template #botao>
                  <div @click="abreModalTipoServicosCalibracao(item)">
                    <v-icon> $plus </v-icon>
                    {{ $t('geral.botoes.servicos_calibracao') }}
                  </div>
                </template>
              </dropdown-padrao>
              <dropdown-padrao
                text
                color="secondary"
                :drop-w100="false"
              >
                <template #botao>
                  <div @click="abreModalTipoServicosManutencao(item)">
                    <v-icon> $plus </v-icon>
                    {{ $t('geral.botoes.servicos_manutencao') }}
                  </div>
                </template>
              </dropdown-padrao>
              <dropdown-padrao
                text
                color="secondary"
                :drop-w100="false"
              >
                <template #botao>
                  <div @click="abreModalPecas(item)">
                    <v-icon> $plus </v-icon>
                    {{ $t('geral.botoes.pecas') }}
                  </div>
                </template>
              </dropdown-padrao>
            </div>
            <div
              class="d-flex mt-1 mb-1 col-5"
              style="padding-left: 100px"
            />
          </div>
        </td>
      </template>
    </v-data-table>

    <PesquisaTipoServicoManutencaoModal
      :show-modal="showModalTipoServicoManutencao"
      :selected="tipoServicoManutencaoSelecionados"
      :single-select="false"
      :instrumentos-selecionados-ids="instrumentosSelecionadosIds"
      @close-modal="manipulaModalPesquisaTipoServicosManutencao('', 'close')"
      @close-modal-add="
        manipulaModalPesquisaTipoServicosManutencao($event, 'add')
      "
    />
    <PesquisaTipoServicoCalibracaoModal
      :show-modal="showModalTipoServicoCalibracao"
      :selected="tipoServicoCalibracaoSelecionados"
      :single-select="false"
      :instrumentos-selecionados-ids="instrumentosSelecionadosIds"
      @close-modal="manipulaModalPesquisaTipoServicosCalibracao('', 'close')"
      @close-modal-add="
        manipulaModalPesquisaTipoServicosCalibracao($event, 'add')
      "
    />
    <PesquisaPecasModal
      :show-modal="showModalPecas"
      :selected="pecasSelecionadas"
      :single-select="false"
      :instrumentos-selecionados-ids="instrumentosSelecionadosIds"
      @close-modal="manipulaModalPesquisaPecas('', 'close')"
      @close-modal-add="manipulaModalPesquisaPecas($event, 'add')"
    />
    <VisualizarUltimosPrecosModal
      ref="modal-visualizar-ultimos-precos"
      :form="form"
    />
    <InstrumentoPorTipoModal
      ref="instrumento-tipo-modal"
      :participante-id="form.participanteClienteId"
      @associarInstrumentos="manipulaModalPesquisaInstrumentos"
    />
    <servico-calibracao-modal
      ref="servico-calibracao-modal"
      :instrumentos-ids="instrumentosSelecionadosIds"
      :local-execucao-id="form.localExecucaoId"
      @remover-servicos="removerViaModal"
      @close-modal="manipulaModalPesquisaTipoServicosCalibracao('', 'close')"
      @close-modal-add="
        manipulaModalPesquisaTipoServicosCalibracao($event, 'add')
      "
    />
    <servico-manutencao-modal
      ref="servico-manutencao-modal"
      :instrumentos-ids="instrumentosSelecionadosIds"
      :local-execucao-id="form.localExecucaoId"
      @remover-servicos="removerViaModal"
      @close-modal="manipulaModalPesquisaTipoServicosManutencao('', 'close')"
      @close-modal-add="
        manipulaModalPesquisaTipoServicosManutencao($event, 'add')
      "
    />
    <pecas-modal
      ref="pecas-modal"
      :instrumentos-ids="instrumentosSelecionadosIds"
      :local-execucao-id="form.localExecucaoId"
      @remover-servicos="removerViaModal"
      @close-modal="manipulaModalPesquisaPecas('', 'close')"
      @close-modal-add="manipulaModalPesquisaPecas($event, 'add')"
    />
    <instrumento-edicao-modal
      ref="modal-edicao-instrumento"
      :instrumento-id="editarInstrumentoId"
      :atualiza="true"
    />
  </div>
</template>
<script>
import ContratoService from '@common/services/cadastros/ContratoService';
import PesquisaPecasModal from '@components/fragments/Modals/PesquisaPecasModal';
import PesquisaTipoServicoCalibracaoModal from '@components/fragments/Modals/PesquisaTipoServicoCalibracaoModal';
import PesquisaTipoServicoManutencaoModal from '@components/fragments/Modals/PesquisaTipoServicoManutencaoModal';
import InstrumentoEdicaoModal from '../../operacao/components/modais/InstrumentoEdicaoModal.vue';
import InstrumentoPorTipoModal from './modais/InstrumentoPorTipoModal.vue';
import PecasModal from './modais/PecasModal.vue';
import ServicoCalibracaoModal from './modais/ServicoCalibracaoModal.vue';
import ServicoManutencaoModal from './modais/ServicoManutencaoModal.vue';
import VisualizarUltimosPrecosModal from './modais/VisualizarUltimosPrecosModal';

export default {
  components: {
    PesquisaTipoServicoManutencaoModal,
    PesquisaTipoServicoCalibracaoModal,
    PesquisaPecasModal,
    VisualizarUltimosPrecosModal,
    InstrumentoPorTipoModal,
    ServicoCalibracaoModal,
    ServicoManutencaoModal,
    PecasModal,
    InstrumentoEdicaoModal,
  },
  props: {
    form: {},
    prazoEntregaPadrao: { type: Number, default: null },
    visualizacao: { type: Boolean, default: false },
  },
  data() {
    return {
      precoServicoContrato: null,
      keyAtualiza: 1,
      optionsInputMoney: {
        locale: 'pt-BR',
        prefix: '',
        suffix: '',
        length: 11,
        precision: 2,
      },
      expanded: [],
      renumeracaoItens: 1,
      instrumentosSelecionadosIds: [],
      instrumentosSelecionados: [],
      tipoServicoManutencaoSelecionados: [],
      tipoServicoCalibracaoSelecionados: [],
      pecasSelecionadas: [],
      singleExpand: false,
      mostraInputs: false,
      showModalTipoServicoManutencao: false,
      showModalTipoServicoCalibracao: false,
      showModalPecas: false,
      adicionadoPrimeiraDescricaoManutencao: false,
      adicionadoPrimeiraDescricaoCalibracao: false,
      calibracaoCampoOuLaboratorio: '',
      primeiroItemCalibracao: {},
      nomeServicoManutencao: '',
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'numero',
            text: this.$t('modulos.orcamentos.formulario.numero'),
            width: 130,
          },
          {
            value: 'instrumento',
            text: this.$t('modulos.orcamentos.formulario.instrumento'),
            width: 160,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.orcamentos.formulario.descricao') + " * ",
            cellClass: 'pl-0',
          },
          {
            value: 'prazoEntrega',
            text: this.$t('modulos.orcamentos.formulario.prazo'),
            width: 90,
            align: 'center',
          },
          {
            value: 'qtd',
            text: this.$t('modulos.orcamentos.formulario.qtd'),
            width: 80,
            align: 'center',
          },
          {
            value: 'valorUnitario',
            text: this.$t('modulos.orcamentos.formulario.valor_unitario'),
            width: 150,
            align: 'end',
          },
          {
            value: 'desconto',
            text: this.$t('modulos.orcamentos.formulario.desconto'),
            width: 140,
            align: 'end',
            sorteable: false,
          },
          {
            value: 'frete',
            text: this.$t('modulos.orcamentos.formulario.frete'),
            align: 'end',
            width: 120,
            sorteable: false,
          },
          {
            value: 'despesas',
            text: this.$t('modulos.orcamentos.formulario.despesas'),
            width: 120,
            align: 'end',
          },
          {
            value: 'valorTotal',
            text: this.$t('modulos.orcamentos.formulario.valor_total'),
            width: 130,
            align: 'end',
          },
        ],
        indexAtualInstrumento: null,
        numeroItemOrcamento: 1,
        numeroItemTipoServicoManutencaoSelecionados: null,
        numeroItemTipoServicoCalibracaoSelecionados: null,
        numeroItemPecasSelecionadas: null,
      },
      editarInstrumentoId: null,
    };
  },
  computed: {
    existeOrcamentoItemDetalhe: function () {
      let existeDetalhe = false;
      this.form.orcamentoItem.map((item) => {
        if (item.orcamentoItemDetalhe.length) existeDetalhe = true;
      });
      return existeDetalhe;
    },
    tooltipAbrirModalInstrumentos: function () {
      if (this.form.participanteClienteId) return '';
      else return this.$t('geral.erros.participante_preencher');
    },
  },

  watch: {
    'form.tipoDesconto': {
      handler() {
        this.alteraTituloHeaderDesconto();
      },
      immediate: true,
    },
  },
  mounted() {
    this.alteraTituloHeaderDesconto();
  },
  methods: {
    alteraTituloHeaderDesconto() {
      if (this.form.tipoDesconto == 'Percentual') {
        this.tabela.colunas[6].text = this.$t(
          'modulos.orcamentos.formulario.desconto_icone_percent'
        );
      } else {
        this.tabela.colunas[6].text = this.$t(
          'modulos.orcamentos.formulario.desconto'
        );
      }
    },
    // trocaSpanInputDetalhes(numeroItem, indexServico, status) {
    //   setTimeout(() => {
    //     this.form.orcamentoItem.map((item) => {
    //       if (item.numeroItem == numeroItem) {
    //         item.orcamentoItemDetalhe[
    //           indexServico
    //         ].trocaSpanInputOrcamentoItemDetalhe = status;
    //       }
    //     });
    //     this.alterarPrazoEntregaItem();
    //     this.keyAtualiza++;
    //   }, 200);
    // },
    // alterarPrazoEntregaItem() {
    //   let maiorPrazo = 0;
    //   this.form.orcamentoItem.forEach((item) => {
    //     item.orcamentoItemDetalhe.forEach((servico, index) => {
    //       if (Number(servico.prazoEntrega) > maiorPrazo)
    //         maiorPrazo = Number(servico.prazoEntrega);

    //       if (Number(servico.prazoEntrega) > Number(item.prazoEntrega))
    //         item.prazoEntrega = servico.prazoEntrega;

    //       if (
    //         index === item.orcamentoItemDetalhe.length - 1 &&
    //         item.prazoEntrega > maiorPrazo
    //       )
    //         item.prazoEntrega = maiorPrazo;
    //     });
    //   });
    // },
    // trocaSpanColocaFocoServico(instrumento, input) {
    //   setTimeout(() => {
    //     this.$refs[input + instrumento.servicoId][0].$el.children[0].focus();
    //   }, 500);
    // },

    trocaSpanColocaFocoDescricao(numItem) {
      this.atualizaSpanInputDescricao(numItem);
      setTimeout(() => {
        this.$refs['inputDescricao' + numItem].focus();
      }, 300);
    },
    atualizaSpanInputDescricao(numItem) {
      this.form.orcamentoItem.map((item) => {
        if (item.numeroItem == numItem) {
          item.trocaSpanInputDescricao = !item.trocaSpanInputDescricao;
        }
      });
      this.keyAtualiza++;
    },
    abrirModalVisualizarUltimosPrecosOrcados() {
      this.$refs['modal-visualizar-ultimos-precos'].abrirModal();
    },
    trocaSpanColocaFocoPrazo(numItem) {
      this.atualizaSpanInputPrazo(numItem);
      setTimeout(() => {
        this.$refs['inputPrazo' + numItem].$el.children[0].focus();
      }, 300);
    },
    atualizaSpanInputPrazo(numItem) {
      this.form.orcamentoItem.map((item) => {
        if (item.numeroItem == numItem) {
          item.trocaSpanInputPrazo = !item.trocaSpanInputPrazo;
        }
      });
      this.keyAtualiza++;
    },
    trocaSpanColocaFocoQuantidade(numItem) {
      this.atualizaSpanInputQuantidade(numItem);
      setTimeout(() => {
        this.$refs['inputQuantidade' + numItem].$el.children[0].focus();
      }, 300);
    },
    atualizaSpanInputQuantidade(numItem) {
      this.form.orcamentoItem.map((item) => {
        if (item.numeroItem == numItem) {
          item.trocaSpanInputQuantidade = !item.trocaSpanInputQuantidade;
        }
      });
      this.keyAtualiza++;
    },
    trocaSpanColocaFocoValorUnitario(numItem) {
      this.atualizaSpanInputValorUnitario(numItem);
      setTimeout(() => {
        this.$refs['inputValorUnitario' + numItem].$el.children[0].focus();
      }, 300);
    },
    atualizaSpanInputValorUnitario(numItem) {
      this.form.orcamentoItem.map((item) => {
        if (item.numeroItem == numItem) {
          item.trocaSpanInputValorUnitario = !item.trocaSpanInputValorUnitario;
        }
      });
      this.keyAtualiza++;
    },
    trocaSpanColocaFocoValorTotal(numItem) {
      this.atualizaSpanInputValorTotal(numItem);
      setTimeout(() => {
        this.$refs['inputValorTotal' + numItem].$el.children[0].focus();
      }, 300);
    },
    atualizaSpanInputValorTotal(numItem) {
      this.form.orcamentoItem.map((item) => {
        if (item.numeroItem == numItem) {
          item.trocaSpanInputValorTotal = !item.trocaSpanInputValorTotal;
        }
      });
      this.keyAtualiza++;
    },
    trocaSpanColocaFocoDesconto(numItem) {
      this.atualizaSpanInputDesconto(numItem);
      setTimeout(() => {
        this.$refs['inputDesconto' + numItem].$el.children[0].focus();
      }, 300);
    },
    atualizaSpanInputDesconto(numItem) {
      this.form.orcamentoItem.map((item) => {
        if (item.numeroItem == numItem) {
          item.trocaSpanInputDesconto = !item.trocaSpanInputDesconto;
        }
      });
      this.keyAtualiza++;
    },
    trocaSpanColocaFocoFrete(numItem) {
      this.atualizaSpanInputFrete(numItem);
      setTimeout(() => {
        this.$refs['inputFrete' + numItem].$el.children[0].focus();
      }, 300);
    },
    atualizaSpanInputFrete(numItem) {
      this.form.orcamentoItem.map((item) => {
        if (item.numeroItem == numItem) {
          item.trocaSpanInputFrete = !item.trocaSpanInputFrete;
        }
      });
      this.keyAtualiza++;
    },
    trocaSpanColocaFocoDespesas(numItem) {
      this.atualizaSpanInputDespesas(numItem);
      setTimeout(() => {
        this.$refs['inputDespesas' + numItem].$el.children[0].focus();
      }, 300);
    },
    atualizaSpanInputDespesas(numItem) {
      this.form.orcamentoItem.map((item) => {
        if (item.numeroItem == numItem) {
          item.trocaSpanInputDespesas = !item.trocaSpanInputDespesas;
        }
      });
      this.keyAtualiza++;
    },
    alterarPrazoServicos(item, numItem) {
      this.atualizaSpanInputPrazo(numItem);
      this.keyAtualiza++;
      this.trocaSpanInputPrazo = !this.trocaSpanInputPrazo;
      item.orcamentoItemDetalhe?.forEach(
        (servico) => (servico.prazoEntrega = item.prazoEntrega)
      );
    },
    manipulaModalPesquisaPecas(pecas = '', type = '') {
      this.showModalPecas = false;

      if (type === 'add') {
        this.form.orcamentoItem.map((item) => {
          if (
            item.numeroItem == this.numeroItemPecasSelecionadas &&
            pecas.selected.length
          ) {
            pecas.selected.map((itemPeca) => {
              item.orcamentoItemDetalhe.push({
                idItem: itemPeca.id,
                codigo: itemPeca.codigo,
                descricao: itemPeca.descricao ? itemPeca.descricao : '',
                prazoEntrega: this.prazoEntregaPadrao ?? item.prazoEntrega,
                quantidade: 1,
                valorUnitario: itemPeca.valorVenda,
                valorTotal: itemPeca.valorVenda,
                desconto: 0,
                valorFrete: 0,
                outrasDespesas: 0,
                quantidadeAprovada: 0,
                numeroItem: item.orcamentoItemDetalhe.length + 1,
                trocaSpanInputOrcamentoItemDetalhe: false,
                itemId: itemPeca.id,
                tipo: 'peça',
              });
            });
          }
          this.calcularLinhaPrincipal(item.numeroItem);
        });
      }
    },
    definirIconeAdicionadoRemovido: function (item) {
      let flag = false;
      if (item.orcamentoItemInstrumento?.length != 0)
        flag = item.orcamentoItemInstrumento[0].flagAdicionadoCliente;
      if (!flag)
        return {
          icon: '$successCircleOutline',
          color: 'primary-success-700--text',
          tooltip: this.$t('modulos.contrato.instrumento_validado'),
        };
      return {
        icon: '$plusCircleOutline',
        color: 'warning--text',
        tooltip: this.$t('modulos.contrato.instrumento_adicionado'),
      };
    },
    montaDescricaoItemOrcamento: function () {
      this.adicionadoPrimeiraDescricaoManutencao = false;
      this.adicionadoPrimeiraDescricaoCalibracao = false;
      this.nomeServicoManutencao = '';
      this.form.orcamentoItem.map((item) => {
        this.nomeServicoManutencao = item.orcamentoItemDetalhe.find(
          (itemDetalhe) => itemDetalhe.tipoServico == 'Manutencao'
        )?.nomeServico
          ? item.orcamentoItemDetalhe.find(
              (itemDetalhe) => itemDetalhe.tipoServico == 'Manutencao'
            )?.nomeServico
          : '';
      });

      setTimeout(() => {
        this.form.orcamentoItem.map((item) => {
          item.descricao = '';
          this.calibracaoCampoOuLaboratorio = '';
          this.primeiroItemCalibracao = item.orcamentoItemDetalhe.find(
            (itemDetalhe) => {
              return itemDetalhe.tipoServico == 'Calibracao';
            }
          );
          item.orcamentoItemDetalhe.map(() => {
            if (this.primeiroItemCalibracao?.localExecucao == 'Laboratório') {
              this.calibracaoCampoOuLaboratorio = 'CALIBRAÇÃO';
            } else this.calibracaoCampoOuLaboratorio = 'CALIBRAÇÃO EM CAMPO';

            if (
              !this.primeiroItemCalibracao?.tipoServico &&
              this.nomeServicoManutencao
            ) {
              item.descricao = this.nomeServicoManutencao;
            } else if (this.primeiroItemCalibracao) {
              const manutencoesNome = item.orcamentoItemDetalhe
                .filter((servico) => servico.tipoServico == 'Manutencao')
                .map((servico) => servico.nomeServico)
                .join('/');

              item.descricao = `${
                this.primeiroItemCalibracao?.nomeServico ?? ''
              }
              ${this.primeiroItemCalibracao.amplitudeMinima ?? ''} a
              ${this.primeiroItemCalibracao?.amplitudeMaxima ?? ''}
              ${this.primeiroItemCalibracao?.unidadeMedidaNome ?? ''} -
              ${this.calibracaoCampoOuLaboratorio}/${manutencoesNome}
              ${
                this.primeiroItemCalibracao.pontos
                  ? 'Em ' + this.primeiroItemCalibracao.pontos + ' Pontos'
                  : ''
              }  ${
                this.primeiroItemCalibracao.descricaoCompleta
                  ? this.primeiroItemCalibracao.descricaoCompleta
                  : ''
              }`;
            }
          });
        });
      }, 600);
    },
    abreModalPecas(item) {
      const {
        numeroItem,
        orcamentoItemDetalhe,
        instrumentosSelecionadosColuna,
      } = item;
      this.numeroItemPecasSelecionadas = numeroItem;
      // this.showModalPecas = true;
      const pecasSelecionadas = orcamentoItemDetalhe
        ?.filter((item) => item.tipo == 'peça')
        ?.map((item) => {
          return {
            id: item.idItem,
            index: numeroItem,
            indexItem: item.numeroItem - 1,
          };
        });

      this.instrumentosSelecionadosIds = instrumentosSelecionadosColuna?.map(
        (instrumento) => instrumento.id
      );

      setTimeout(() => {
        this.$refs['pecas-modal'].abrirModal(pecasSelecionadas);
      }, 100);
    },
    abreModalTipoServicosManutencao(item) {
      const {
        numeroItem,
        orcamentoItemDetalhe,
        instrumentosSelecionadosColuna,
      } = item;
      this.numeroItemTipoServicoManutencaoSelecionados = numeroItem;

      const servicoManutencaoSelecionado = orcamentoItemDetalhe
        ?.filter((item) => item.tipoServico == 'Manutencao')
        ?.map((item) => {
          return {
            id: item.idItem,
            index: numeroItem,
            indexItem: item.numeroItem - 1,
          };
        });

      this.instrumentosSelecionadosIds = instrumentosSelecionadosColuna?.map(
        (instrumento) => instrumento.id
      );
      setTimeout(() => {
        this.$refs['servico-manutencao-modal'].abrirModal(
          servicoManutencaoSelecionado
        );
      }, 100);
    },
    async manipulaModalPesquisaTipoServicosManutencao(
      tipoServicoManutencao = '',
      type = ''
    ) {
      this.showModalTipoServicoManutencao = false;

      if (type === 'add') {
        this.form.orcamentoItem.map((item) => {
          if (
            item.numeroItem ==
              this.numeroItemTipoServicoManutencaoSelecionados &&
            tipoServicoManutencao.selected.length
          ) {
            tipoServicoManutencao.selected.map(async (itemTipoServico) => {
              if (item.orcamentoItemInstrumento) {
                await this.validaPrecoServico(
                  itemTipoServico,
                  item.orcamentoItemInstrumento[0]?.instrumentoId
                );
              }

              item.orcamentoItemDetalhe.push({
                idItem: itemTipoServico.id,
                codigo: itemTipoServico.codigo,
                descricao: itemTipoServico.nome,
                descricaoPrincipal: ` ${itemTipoServico.nome} ${itemTipoServico.descricao}`,
                nomeServico: `${itemTipoServico.nome}`,
                trocaSpanInputOrcamentoItemDetalhe: false,
                prazoEntrega: this.prazoEntregaPadrao ?? item.prazoEntrega,
                quantidade: 1,
                valorUnitario: this.precoServicoContrato
                  ? this.precoServicoContrato
                  : itemTipoServico.valorServico,
                valorTotal: this.precoServicoContrato
                  ? this.precoServicoContrato
                  : itemTipoServico.valorServico,
                desconto: itemTipoServico.desconto
                  ? itemTipoServico.desconto
                  : 0,
                valorFrete: 0,
                outrasDespesas: 0,
                quantidadeAprovada: 0,
                numeroItem: item.orcamentoItemDetalhe.length + 1,
                servicoId: itemTipoServico.id,
                tipo: 'serviço',
                tipoServico: itemTipoServico.tipoServico,
              });
            });
            this.calcularLinhaPrincipal(item.numeroItem);
          }
        });
        this.montaDescricaoItemOrcamento();
      }
    },
    abreModalTipoServicosCalibracao(item) {
      const {
        numeroItem,
        orcamentoItemDetalhe,
        instrumentosSelecionadosColuna,
      } = item;
      this.numeroItemTipoServicoCalibracaoSelecionados = numeroItem;

      const servicoCalibracaoSelecionado = orcamentoItemDetalhe
        ?.filter((item) => item.tipoServico == 'Calibracao')
        ?.map((item) => {
          return {
            id: item.idItem,
            index: numeroItem,
            indexItem: item.numeroItem - 1,
          };
        });
      this.instrumentosSelecionadosIds = instrumentosSelecionadosColuna?.map(
        (instrumento) => instrumento.id
      );

      setTimeout(() => {
        this.$refs['servico-calibracao-modal'].abrirModal(
          servicoCalibracaoSelecionado
        );
      }, 100);
    },
    async validaPrecoServico(servico, instrumentoId) {
      if (servico || instrumentoId) return; //verificar endpoint
      if (instrumentoId) {
        this.precoServicoContrato = null;
        let params = {
          participanteId: this.form.participanteClienteId,
          instrumentoId: instrumentoId,
          servicoId: servico.id,
        };

        await ContratoService.buscarServicoContrato(params)
          .then((res) => {
            if (res.data) {
              this.precoServicoContrato = res.data.valorServico;
            }
          })
          .catch(() => {
            this.toastAlerta(
              this.$t(`geral.erros.instrumento_contrato_associado`)
            );
          });
      }
    },
    async manipulaModalPesquisaTipoServicosCalibracao(
      tipoServicoCalibracao = '',
      type = ''
    ) {
      this.showModalTipoServicoCalibracao = false;

      if (type === 'add') {
        this.form.orcamentoItem.map((item) => {
          if (
            item.numeroItem ==
              this.numeroItemTipoServicoCalibracaoSelecionados &&
            tipoServicoCalibracao.selected.length
          ) {
            tipoServicoCalibracao.selected.map(async (itemTipoServico) => {
              if (item.orcamentoItemInstrumento) {
                //enviando o item.orcamentoItemInstrumento[0].instrumentoId até um retorno de definição do cliente
                await this.validaPrecoServico(
                  itemTipoServico,
                  item.orcamentoItemInstrumento[0]?.instrumentoId
                );
              }
              item.orcamentoItemDetalhe.push({
                idItem: itemTipoServico.id,
                codigo: itemTipoServico.codigo,
                descricao: itemTipoServico.nome,
                descricaoPrincipal: '',


                nomeServico: `${itemTipoServico.nome}`,
                amplitudeMinima: `${itemTipoServico.amplitudeMinima}`,
                amplitudeMaxima: `${itemTipoServico.amplitudeMaxima}`,
                unidadeMedidaNome: `${itemTipoServico.unidadeMedida?.nome}`,
                pontos: `${itemTipoServico.minimoPontos}`,
                descricaoCompleta: `- ${itemTipoServico.descricao}`,
                localExecucao: `${itemTipoServico.localExecucao.nome}`,
                tipoCalibracao: `${itemTipoServico.tipoCalibracao.nome}`,

                trocaSpanInputOrcamentoItemDetalhe: false,
                prazoEntrega: this.prazoEntregaPadrao ?? item.prazoEntrega,
                quantidade: 1,
                valorUnitario: this.precoServicoContrato
                  ? this.precoServicoContrato
                  : itemTipoServico.valorServico,
                valorTotal: this.precoServicoContrato
                  ? this.precoServicoContrato
                  : itemTipoServico.valorServico,
                desconto: itemTipoServico.desconto
                  ? itemTipoServico.desconto
                  : 0,
                valorFrete: 0,
                outrasDespesas: 0,
                quantidadeAprovada: 0,
                numeroItem: item.orcamentoItemDetalhe.length + 1,
                servicoId: itemTipoServico.id,
                tipo: 'serviço',
                tipoServico: itemTipoServico.tipoServico,
              });
            });
            this.calcularLinhaPrincipal(item.numeroItem);
          }
        });
        this.montaDescricaoItemOrcamento();
      }
    },
    removerViaModal(itens) {
      itens?.forEach((item, index) => {
        const buscarIndexParaRemover = this.form.orcamentoItem[
          item.index - 1
        ].orcamentoItemDetalhe.findIndex(
          (itemDetalhe) => itemDetalhe.idItem == item.id
        );

        if (buscarIndexParaRemover != -1) {
          this.form.orcamentoItem[item.index - 1].orcamentoItemDetalhe.splice(
            buscarIndexParaRemover,
            1
          );

          this.calcularLinhaPrincipal(item.numeroItem);
          if (index == itens.length - 1) {
            this.montaDescricaoItemOrcamento();
            this.calculaValorTotalGeral(item.index);
          }
        }
      });
      // this.calculaValorTotal(numeroItem, index);
    },
    removerInstrumento(indexItem, indexInstrumento) {
      this.form.orcamentoItem.map((item, index) => {
        if (index == indexItem) {
          item.orcamentoItemInstrumento.splice(indexInstrumento, 1);
          item.instrumentosSelecionadosColuna.splice(indexInstrumento, 1);
          item.instrumentosNomes.splice(indexInstrumento, 1);
        }
      });

      this.keyAtualiza++;
    },
    abreModalEditarInstrumento(item, nome) {
      let instrumento = item.orcamentoItemInstrumento?.find(
        (el) => el.nome == nome
      );
      if (!instrumento)
        instrumento = item.instrumentosSelecionadosColuna?.find(
          (el) => el.codigoTag == nome
        );
      this.editarInstrumentoId = instrumento?.instrumentoId ?? instrumento?.id;
      if (!instrumento)
        return console.error(
          'Não foi possível encontrar o instrumento para edição'
        );
      this.$refs['modal-edicao-instrumento'].abrirModal();
    },
    abreModalInstrumentos(index) {
      this.indexAtualInstrumento = index;
      this.$refs['instrumento-tipo-modal'].abrirModal();

      this.instrumentosSelecionados = [];
      this.form.orcamentoItem.map((item, indexOrcamento) => {
        if (indexOrcamento == this.indexAtualInstrumento) {
          if (indexOrcamento == this.indexAtualInstrumento) {
            this.instrumentosSelecionados = item.instrumentosSelecionadosColuna;
          }
        }
      });
    },
    inserirInstrumentosOrcamento(instrumentos = '', type = '') {
      if (type === 'add') {
        this.form.orcamentoItem.map((item, index) => {
          if (index == this.indexAtualInstrumento) {
            item.instrumentosNomes = [];
            item.orcamentoItemInstrumento = [];
          }
        });

        this.form.orcamentoItem.map((item, index) => {
          if (index == this.indexAtualInstrumento) {
            instrumentos.selected.map((itemInstrumentos) => {
              if (
                !item.instrumentosNomes.includes(itemInstrumentos.codigoTag)
              ) {
                item.instrumentosNomes.push(itemInstrumentos.codigoTag);
                item.orcamentoItemInstrumento.push({
                  instrumentoId: itemInstrumentos.id,
                });
              }
              item.instrumentosSelecionadosColuna = [...instrumentos.selected];
            });
          }
        });
      }
    },
    manipulaModalPesquisaInstrumentos(instrumentos = '', type = '') {
      if (instrumentos?.selected?.length == 1) {
        this.inserirInstrumentosOrcamento(instrumentos, type);
        return;
      }
      this.confirmarAgruparInstrumentos()
        .then(() => {
          this.inserirInstrumentosOrcamento(instrumentos, type);
        })
        .catch(() => {
          if (type === 'add') {
            this.form.orcamentoItem.splice(this.indexAtualInstrumento, 1);

            instrumentos.selected.map((item) => {
              this.form.orcamentoItem.push({
                numeroItem: this.form.orcamentoItem.length + 1,
                orcamentoItemInstrumento: [{ instrumentoId: item.id }],
                descricao: '',
                prazoEntrega: this.prazoEntregaPadrao,
                quantidade: 1,
                valorUnitario: 0,
                valorTotal: 0,
                desconto: 0,
                valorFrete: 0,
                outrasDespesas: 0,
                instrumentosNomes: [item.codigoTag],
                instrumentosSelecionadosColuna: [...instrumentos.selected],
                orcamentoItemDetalhe: [],
                valorTotalCalculadoGeral: 0,
                quantidadeAprovada: 0,
                dataAprovacao: null,
              });
            });
          }
        });
      this.keyAtualiza++;
    },
    adicionaItemOrcamento() {
      this.form.orcamentoItem.push({
        numeroItem: this.form.orcamentoItem.length + 1,
        orcamentoItemInstrumento: null,
        trocaSpanInputDescricao: false,
        descricao: '',
        prazoEntrega: this.prazoEntregaPadrao,
        trocaSpanInputPrazo: false,
        quantidade: 1,
        trocaSpanInputQuantidade: false,
        valorUnitario: 0,
        trocaSpanInputValorUnitario: false,
        valorTotal: 0,
        trocaSpanInputValorTotal: false,
        desconto: 0,
        trocaSpanInputDesconto: false,
        valorFrete: 0,
        trocaSpanInputFrete: false,
        outrasDespesas: 0,
        trocaSpanInputDespesas: false,
        instrumentosNomes: [],
        instrumentosSelecionadosColuna: [],
        orcamentoItemDetalhe: [],
        valorTotalCalculadoGeral: 0,
        quantidadeAprovada: 0,
        dataAprovacao: null,
      });
      this.expanded.push({
        numeroItem: this.form.orcamentoItem.length,
        orcamentoItemInstrumento: null,
        descricao: '',
        prazoEntrega: this.prazoEntregaPadrao,
        quantidade: 1,
        valorUnitario: 0,
        valorTotal: 0,
        desconto: 0,
        valorFrete: 0,
        outrasDespesas: 0,
        instrumentosNomes: [],
        instrumentosSelecionadosColuna: [],
        orcamentoItemDetalhe: [],
        valorTotalCalculadoGeral: 0,
        quantidadeAprovada: 0,
        dataAprovacao: null,
      });
    },
    duplicar(item) {
      let itemDuplicar = JSON.parse(JSON.stringify(item));
      this.form.orcamentoItem.push({
        ...itemDuplicar,
        numeroItem: this.renumeracaoItens++,
      });

      this.recalculaValorTotal();
    },
    excluir(index) {
      this.renumeracaoItens = 1;
      this.expanded.splice(index, 1);
      this.form.orcamentoItem.splice(index, 1);
      this.form.orcamentoItem.map((item) => {
        item.numeroItem = this.renumeracaoItens++;
      });

      this.recalculaValorTotal();
    },
    recalculaValorTotal() {
      this.form.valorTotalItens = this.form.orcamentoItem.reduce((total, item) => {
        return total + (item.valorUnitario * item.quantidade);
      }, 0);
    },
    removerTipoServicosEPecas(numeroItem, index) {
      this.form.orcamentoItem.map((item) => {
        if (item.numeroItem == numeroItem) {
          item.orcamentoItemDetalhe.map((itemDetalhes, indexDetalhes) => {
            if (index == indexDetalhes) {
              item.orcamentoItemDetalhe.splice(index, 1);
            }
          });
        }
      });
      // this.calculaValorTotal(numeroItem, index);
      this.montaDescricaoItemOrcamento();
      this.calcularLinhaPrincipal(numeroItem);
    },
    calculaValoresTotalItemPrincipal(itemNumero) {
      this.form.orcamentoItem.forEach((item) => {
        if (
          item.numeroItem == itemNumero &&
          item.quantidade &&
          item.valorUnitario
        ) {
          // Percentual e Valor
          let valorItemQtd =
            Number(item.quantidade) * Number(item.valorUnitario);
          if (this.form.tipoDesconto == 'Percentual') {
            valorItemQtd =
              valorItemQtd - valorItemQtd * ((item?.desconto || 0) / 100);
          } else {
            valorItemQtd = valorItemQtd - (item?.desconto || 0);
          }

          item.valorTotal =
            valorItemQtd +
            Number(item.valorFrete) +
            Number(item.outrasDespesas);
        }
      });

      this.calculaValorFinal();
    },
    calculaValorTotal(numeroItem, index) {
      this.form.orcamentoItem.map((item) => {
        if (item.numeroItem == numeroItem) {
          item.valorTotalCalculadoGeral = 0;
          item.orcamentoItemDetalhe.map((itemDetalhes, indexDetalhes) => {
            if (
              index == indexDetalhes &&
              itemDetalhes.quantidade &&
              itemDetalhes.valorUnitario
            ) {
              let valorItemQtd =
                Number(itemDetalhes.quantidade) *
                Number(itemDetalhes.valorUnitario);
              if (this.form.tipoDesconto == 'Percentual') {
                valorItemQtd =
                  valorItemQtd -
                  valorItemQtd * ((itemDetalhes?.desconto || 0) / 100);
              } else {
                valorItemQtd = valorItemQtd - (itemDetalhes?.desconto || 0);
              }

              itemDetalhes.valorTotal =
                valorItemQtd +
                Number(itemDetalhes.valorFrete) +
                Number(itemDetalhes.outrasDespesas);

              // itemDetalhes.valorTotal =
              //   Number(itemDetalhes.quantidade) *
              //   Number(itemDetalhes.valorUnitario);
            }
            if (itemDetalhes.valorTotal) {
              let calculoTotal =
                Number(item.valorTotalCalculadoGeral) +
                Number(itemDetalhes.valorTotal);
              item.valorTotalCalculadoGeral = calculoTotal;
              item.valorUnitario = calculoTotal;
            }
          });
        }
      });

      this.calculaValoresTotalItemPrincipal(numeroItem);
    },
    calculaValorTotalGeral(numeroItem) {
      this.form.orcamentoItem.map((item) => {
        if (item.numeroItem == numeroItem) {
          item.valorTotalCalculadoGeral = 0;
          item.orcamentoItemDetalhe.map((itemDetalhes) => {
            if (itemDetalhes.valorTotal) {
              let calculoTotal =
                Number(item.valorTotalCalculadoGeral) +
                Number(itemDetalhes.valorTotal);
              item.valorTotalCalculadoGeral = calculoTotal;
              item.valorUnitario = calculoTotal;
            }
          });
          if (item.orcamentoItemDetalhe?.length == 0) {
            item.valorTotalCalculadoGeral = 0;
            item.valorUnitario = 0;
            item.valorTotal = 0;
          }
        }
      });

      setTimeout(() => {
        this.calculaValoresTotalItemPrincipal(numeroItem);
      }, 200);
    },
    calculaValorFinal() {
      //alterações de regras alterar para o arquivo dadosOrçamento tambem
      this.form.valorTotalItens = 0;
      this.form.valorTotalDesconto = 0;
      this.form.valorTotalFrete = 0;
      this.form.valorTotalOutrosDespesas = 0;

      this.form.orcamentoItem.map((item) => {
        if (!item.desconto) item.desconto = 0;
        if (this.form.tipoDesconto != 'Percentual') {
          item.valorTotal = parseFloat(item.valorTotal);
          item.valorFrete = parseFloat(item.valorFrete);
          item.outrasDespesas = parseFloat(item.outrasDespesas);
          item.desconto = parseFloat(item.desconto);
          this.form.valorTotalItens =
            Number(this.form.valorTotalItens) +
            Number(item.valorTotal) +
            Number(item.valorFrete) +
            Number(item.outrasDespesas) -
            Number(item.desconto);

          // this.form.valorTotalItens = this.form.valorTotalItens.toFixed(2);
        } else {
          item.valorTotal = parseFloat(item.valorTotal);
          item.valorFrete = parseFloat(item.valorFrete);
          item.outrasDespesas = parseFloat(item.outrasDespesas);
          item.desconto = parseFloat(item.desconto);
          this.descontoPercentual = item.desconto;
          if (item.desconto) {
            this.descontoPercentual = item.valorTotal * (item.desconto / 100);
          }
          this.form.valorTotalItens +=
            Number(item.valorTotal) +
            Number(item.valorFrete) +
            Number(item.outrasDespesas) -
            Number(this.descontoPercentual);

          // this.form.valorTotalItens = this.form.valorTotalItens.toFixed(2);
        }

        this.form.valorTotalDesconto += parseFloat(item.desconto);
        this.form.valorTotalFrete += parseFloat(item.valorFrete);
        this.form.valorTotalOutrosDespesas += parseFloat(item.outrasDespesas);
      });
    },
    calculaDescontoItens(indexItem, alterarServicos = false) {
      this.form.orcamentoItem.map((item, index) => {
        if (item.desconto == 0) {
          item.orcamentoItemDetalhe.forEach((itemServico) => {
            itemServico.desconto = 0;
          });
          return;
        }
        if (alterarServicos && this.form.tipoDesconto == 'Percentual') {
          item.orcamentoItemDetalhe.forEach((itemServico) => {
            itemServico.desconto = item.desconto;
          });
          this.calculaValoresTotalItemPrincipal(item.numeroItem);
          return;
        }
        if (
          index == indexItem &&
          item.orcamentoItemDetalhe?.length &&
          item.desconto &&
          item.valorTotal
        ) {
          console.log('3');
          if (
            (this.form.tipoDesconto == 'Valor' &&
              item.desconto <= item.valorTotal) ||
            (this.form.tipoDesconto == 'Percentual' && item.desconto < 100)
          ) {
            console.log(item);
            let valorCalculoDescontoIndividual =
              Number(item.desconto) / Number(item.valorUnitario);

            item.orcamentoItemDetalhe.map((itemDetalhe) => {
              if (item.orcamentoItemDetalhe.length == 1) {
                itemDetalhe.desconto = Number(item.desconto);
              } else {
                itemDetalhe.desconto = (
                  Number(itemDetalhe.valorTotal) *
                  Number(valorCalculoDescontoIndividual)
                ).toFixed(2);
                itemDetalhe.desconto = Number(itemDetalhe.desconto);
              }
            });
          } else {
            item.desconto = 0;
            item.valorTotal =
              Number(item.valorUnitario) * Number(item.quantidade);

            item.orcamentoItemDetalhe.map((itemServico) => {
              itemServico.desconto = 0;
            });
          }
        }
      });

      this.calculaValorFinal();
    },
    calculaFreteItens(indexItem) {
      this.form.orcamentoItem.map((item, index) => {
        if (item.valorFrete == 0) {
          item.orcamentoItemDetalhe.map((itemServico) => {
            itemServico.valorFrete = 0;
          });
          return;
        }
        if (
          index == indexItem &&
          item.orcamentoItemDetalhe?.length &&
          item.valorFrete
        ) {
          item.orcamentoItemDetalhe.map((itemDetalhe) => {
            itemDetalhe.valorFrete = (
              Number(item.valorFrete) / item.orcamentoItemDetalhe.length
            ).toFixed(2);
            itemDetalhe.valorFrete = Number(itemDetalhe.valorFrete);
          });
        }
      });

      this.calculaValorFinal();
    },
    calculaDespesasItens(indexItem) {
      this.form.orcamentoItem.map((item, index) => {
        if (item.outrasDespesas == 0) {
          item.orcamentoItemDetalhe.map((itemServico) => {
            itemServico.outrasDespesas = 0;
          });
          return;
        }
        if (
          index == indexItem &&
          item.orcamentoItemDetalhe?.length &&
          item.outrasDespesas
        ) {
          item.orcamentoItemDetalhe.map((itemDetalhe) => {
            itemDetalhe.outrasDespesas = (
              Number(item.outrasDespesas) / item.orcamentoItemDetalhe.length
            ).toFixed(2);
            itemDetalhe.outrasDespesas = Number(itemDetalhe.outrasDespesas);
          });
        }
      });

      this.calculaValorFinal();
    },
    calculaDescontoServicos(numeroItem) {
      this.form.orcamentoItem.map((item) => {
        if (item.numeroItem == numeroItem) {
          item.desconto = 0;
          item.orcamentoItemDetalhe.map((itemDetalhes, index) => {
            if (
              (this.form.tipoDesconto == 'Valor' &&
                itemDetalhes.desconto <=
                  Number(itemDetalhes.valorUnitarrnhubio) *
                    Number(itemDetalhes.quantidade)) ||
              (this.form.tipoDesconto == 'Percentual' &&
                itemDetalhes.desconto < 100)
            ) {
              if (
                this.form.tipoDesconto == 'Percentual' &&
                itemDetalhes.desconto
              ) {
                // let valorTotalServicoDesconto =
                //   itemDetalhes.valorTotal -
                //   itemDetalhes.valorTotal * (itemDetalhes.desconto / 100);

                // let proporcaoDescontoItem =
                //   (1 - valorTotalServicoDesconto / item.valorUnitario) * 100;
                let valorTotalServicoDesconto =
                  itemDetalhes.valorTotal * (itemDetalhes.desconto / 100);

                let proporcaoDescontoItem =
                  (valorTotalServicoDesconto / item.valorUnitario) * 100;

                proporcaoDescontoItem = proporcaoDescontoItem.toFixed(2);

                item.desconto += Number(proporcaoDescontoItem);
                item.desconto = Number(item.desconto);
              } else {
                item.desconto += Number(itemDetalhes.desconto);
                item.desconto = Number(item.desconto);
              }
            } else {
              itemDetalhes.desconto = 0;
              itemDetalhes.valorTotal =
                Number(itemDetalhes.valorUnitario) *
                Number(itemDetalhes.quantidade);
            }
            this.calculaValorTotal(numeroItem, index);
          });
        }
      });

      setTimeout(() => {
        this.calculaValorFinal();
        // this.calculaValorTotalGeral(numeroItem, indexServico);
      }, 200);
    },
    calculaFreteServicos(numeroItem) {
      this.form.orcamentoItem.map((item) => {
        if (item.numeroItem == numeroItem) {
          item.valorFrete = 0;
          item.orcamentoItemDetalhe.map((itemDetalhes, indexItemDetalhes) => {
            item.valorFrete += Number(itemDetalhes.valorFrete);
            item.valorFrete = Number(item.valorFrete);
            this.calculaValorTotal(numeroItem, indexItemDetalhes);
          });
        }
      });

      setTimeout(() => {
        this.calculaValorFinal();
        // this.calculaValorTotalGeral(numeroItem, indexServico);
      }, 200);
    },
    calculaDespesasServicos(numeroItem) {
      this.form.orcamentoItem.map((item) => {
        if (item.numeroItem == numeroItem) {
          item.outrasDespesas = 0;
          item.orcamentoItemDetalhe.map((itemDetalhes, indexItemDetalhes) => {
            item.outrasDespesas += Number(itemDetalhes.outrasDespesas);
            item.outrasDespesas = Number(item.outrasDespesas);
            this.calculaValorTotal(numeroItem, indexItemDetalhes);
          });
        }
      });

      setTimeout(() => {
        this.calculaValorFinal();
        // this.calculaValorTotalGeral(numeroItem, indexServico);
      }, 200);
    },

    // A PARTIR DAQUI, MÉTODOS REFATORADOS

    selecionarConteudo: function (event) {
      event.target.select();
    },

    calcularLinhasItens: function (
      numeroItem,
      indexItemDetalhe,
      ItemOrcamentoDetalhe
    ) {
      const linha = ItemOrcamentoDetalhe[indexItemDetalhe];

      let valorItemQtd = Number(linha.quantidade) * Number(linha.valorUnitario);
      if (this.form.tipoDesconto == 'Percentual') {
        valorItemQtd =
          valorItemQtd - valorItemQtd * ((linha?.desconto || 0) / 100);
      } else {
        valorItemQtd = valorItemQtd - (linha?.desconto || 0);
      }

      linha.valorTotal =
        valorItemQtd + Number(linha.valorFrete) + Number(linha.outrasDespesas);

      this.calcularLinhaPrincipal(numeroItem);
    },
    calcularLinhaPrincipal: function (numeroItem) {
      const linhaPrincipal = this.form.orcamentoItem.find(
        (item) => item.numeroItem == numeroItem
      );
      const somatorioLinhas = linhaPrincipal.orcamentoItemDetalhe.reduce(
        (acc, linha) => {
          acc.valorUnitarioTotal +=
            (Number(linha?.valorUnitario) || 0) *
            (Number(linha?.quantidade) || 0);
          acc.valorFreteTotal += Number(linha?.valorFrete) || 0;
          acc.outrasDespesasTotal += Number(linha?.outrasDespesas) || 0;
          acc.valorTotal += Number(linha?.valorTotal) || 0;
          acc.maiorPrazo = Math.max(acc.maiorPrazo, linha?.prazoEntrega || 0);
          return acc;
        },
        {
          valorUnitarioTotal: 0,
          valorFreteTotal: 0,
          outrasDespesasTotal: 0,
          valorTotal: 0,
          maiorPrazo: 0,
        }
      );

      linhaPrincipal.valorUnitario = somatorioLinhas.valorUnitarioTotal;
      linhaPrincipal.valorFrete = somatorioLinhas.valorFreteTotal;
      linhaPrincipal.outrasDespesas = somatorioLinhas.outrasDespesasTotal;
      linhaPrincipal.valorTotal = somatorioLinhas.valorTotal;
      linhaPrincipal.prazoEntrega = somatorioLinhas.maiorPrazo;

      //Calcular Desconto

      if (this.form.tipoDesconto == 'Percentual') {
        if (linhaPrincipal?.valorUnitario)
          linhaPrincipal.desconto = Number(
            (1 -
              (linhaPrincipal?.valorTotal -
                linhaPrincipal?.valorFrete -
                linhaPrincipal?.outrasDespesas) /
                linhaPrincipal?.valorUnitario) *
              100
          )
            .toFixed(2)
            ?.toString()
            ?.replace('.', ',');
        else linhaPrincipal.desconto = 0;
      } else {
        linhaPrincipal.desconto = Number(
          linhaPrincipal?.valorUnitario -
            (linhaPrincipal?.valorTotal -
              linhaPrincipal?.valorFrete -
              linhaPrincipal?.outrasDespesas)
        )
          .toFixed(2)
          ?.toString()
          ?.replace('.', ',');
      }
      this.calcularTotalOrcamento();
    },
    calcularTotalOrcamento: function () {
      //this.form.valorTotalItens
      const valoresTotais = this.form.orcamentoItem.reduce(
        (acc, linha) => {
          acc;
          acc.valorUnitarioTotal +=
            (Number(linha?.valorUnitario) || 0) *
            (Number(linha?.quantidade) || 0);
          acc.valorTotalFrete += Number(linha?.valorFrete) || 0;
          acc.valorTotalOutrasDespesas += Number(linha?.outrasDespesas) || 0;
          acc.valorTotalItens += Number(linha?.valorTotal) || 0;
          return acc;
        },
        {
          valorUnitarioTotal: 0,
          valorTotalItens: 0,
          valorTotalFrete: 0,
          valorTotalOutrasDespesas: 0,
        }
      );
      this.form.valorTotalItens = valoresTotais.valorTotalItens;
      this.form.valorTotalFrete = valoresTotais.valorTotalFrete;
      this.form.valorTotalOutrasDespesas =
        valoresTotais.valorTotalOutrasDespesas;

      if (this.form.tipoDesconto == 'Percentual') {
        if (valoresTotais?.valorUnitarioTotal)
          this.form.valorTotalDesconto = Number(
            (1 -
              (valoresTotais?.valorTotalItens -
                valoresTotais.valorTotalFrete -
                valoresTotais.valorTotalOutrasDespesas) /
                valoresTotais?.valorUnitarioTotal) *
              100
          )?.toFixed(2);
        else this.form.valorTotalDesconto = 0;
      } else {
        this.form.valorTotalDesconto = Number(
          valoresTotais?.valorUnitarioTotal -
            (valoresTotais?.valorTotalItens -
              valoresTotais.valorTotalFrete -
              valoresTotais.valorTotalOutrasDespesas)
        )?.toFixed(2);
      }
    },
    existeItemReprovado: function(linha){
      return linha.orcamentoItemDetalhe.some(item => item.quantidadeAprovada != item.quantidade);
    },

  },
};
</script>
<style lang="scss">
.description-tr {
  line-height: 16px;
}

.th-simple-table {
  background-color: #d0d5dd;
}

.align-center {
  text-align-last: center !important;
}
.align-end {
  text-align: end !important;
  display: revert !important;
}

.cursor-pointer {
  cursor: pointer;
}

.texto-descricao {
  max-width: 25ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-data-table {
  line-height: 0.5 !important;
}

.v-simple-table {
  line-height: 0.5 !important;
}

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}
.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.5);
}
.descricao-obrigatorio {
  position: relative;
  .descricao-obrigatorio-asterisco {
    position: absolute;
    top: -4px;
    right: -4px;
  }
}
</style>

<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <div />
      <botao-padrao
        v-if="!visualizacao"
        class="mt-3"
        @click="abrirModalMensagem"
      >
        {{ $t('modulos.orcamentos.formulario.selecionar_mensagem') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-model="tabela.selecionados"
      :dados="form.orcamentoMensagem"
      class="mt-2"
      :colunas="tabela.colunas"
      selecionar-apenas-um
    />
    <div
      v-if="tabela.selecionados.length > 0"
      class="row mx-1 my-2 text-red"
    >
      <span class="span-total-black col-12">
        {{ tabela.selecionados[0].descricao }}
      </span>
    </div>
    <MensagemModal
      ref="modal-mensagem"
      :form="form"
      @novaMensagem="novaMensagem"
    />
  </div>
</template>
<script>
import MensagemModal from './modais/MensagemModal';
export default {
  components: {
    MensagemModal,
  },
  props: {
    form: {},
    visualizacao: { type: Boolean, default: false },
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'titulo',
            text: this.$t('modulos.orcamentos.tabela.nome'),
            sortable: true,
            width: 300,
          },
          {
            value: 'descricaoClassificacao',
            text: this.$t('modulos.orcamentos.tabela.classificacao'),
            sortable: true,
          },
        ],
      },
    };
  },
  methods: {
    abrirModalMensagem() {
      this.$refs['modal-mensagem'].abrirModal();
    },
    novaMensagem: function (itens) {
      this.form.orcamentoMensagem = itens.map(
        (item) =>
          (item = {
            ...item,
            mensagemOrcamentoId: item.id,
          })
      );
    },
  },
};
</script>
